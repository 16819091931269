// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Data_Id$Coronate from "./Data_Id.bs.js";
import * as Data_Rounds$Coronate from "./Data_Rounds.bs.js";
import * as Data_Scoring$Coronate from "./Data_Scoring.bs.js";

function make(id, name) {
  return {
          id: id,
          name: name,
          date: new Date(),
          playerIds: Belt_Set.make(Data_Id$Coronate.id),
          scoreAdjustments: Belt_Map.make(Data_Id$Coronate.id),
          byeQueue: [],
          tieBreaks: [
            /* Median */0,
            /* Solkoff */1,
            /* Cumulative */2,
            /* CumulativeOfOpposition */3
          ],
          roundList: Data_Rounds$Coronate.empty
        };
}

function decode(json) {
  var d = Belt_Option.getExn(Js_json.decodeObject(json));
  return {
          id: Data_Id$Coronate.decode(Belt_Option.getExn(Js_dict.get(d, "id"))),
          name: Belt_Option.getExn(Belt_Option.flatMap(Js_dict.get(d, "name"), Js_json.decodeString)),
          date: Belt_Option.getExn(Belt_Option.map(Js_dict.get(d, "date"), (function (json) {
                      var s = Js_json.decodeString(json);
                      if (s !== undefined) {
                        return new Date(s);
                      } else {
                        return json;
                      }
                    }))),
          playerIds: Belt_Set.fromArray(Belt_Array.map(Belt_Option.getExn(Belt_Option.flatMap(Js_dict.get(d, "playerIds"), Js_json.decodeArray)), Data_Id$Coronate.decode), Data_Id$Coronate.id),
          scoreAdjustments: Belt_Map.fromArray(Belt_Array.keepMap(Belt_Array.keepMap(Belt_Option.getWithDefault(Belt_Option.flatMap(Js_dict.get(d, "scoreAdjustments"), Js_json.decodeArray), []), Js_json.decodeArray), (function (a) {
                      var match = Belt_Array.get(a, 0);
                      var match$1 = Belt_Array.get(a, 1);
                      if (match === undefined) {
                        return ;
                      }
                      if (match$1 === undefined) {
                        return ;
                      }
                      var v = Js_json.decodeNumber(Caml_option.valFromOption(match$1));
                      if (v !== undefined) {
                        return [
                                Data_Id$Coronate.decode(Caml_option.valFromOption(match)),
                                v
                              ];
                      }
                      
                    })), Data_Id$Coronate.id),
          byeQueue: Belt_Array.map(Belt_Option.getExn(Belt_Option.flatMap(Js_dict.get(d, "byeQueue"), Js_json.decodeArray)), Data_Id$Coronate.decode),
          tieBreaks: Belt_Array.map(Belt_Option.getExn(Belt_Option.flatMap(Js_dict.get(d, "tieBreaks"), Js_json.decodeArray)), Data_Scoring$Coronate.TieBreak.decode),
          roundList: Data_Rounds$Coronate.decode(Belt_Option.getExn(Js_dict.get(d, "roundList")))
        };
}

function encode(data) {
  return Js_dict.fromArray([
              [
                "id",
                Data_Id$Coronate.encode(data.id)
              ],
              [
                "name",
                data.name
              ],
              [
                "date",
                data.date.toJSON()
              ],
              [
                "playerIds",
                Belt_Array.map(Belt_Set.toArray(data.playerIds), Data_Id$Coronate.encode)
              ],
              [
                "byeQueue",
                Belt_Array.map(data.byeQueue, Data_Id$Coronate.encode)
              ],
              [
                "tieBreaks",
                Belt_Array.map(data.tieBreaks, Data_Scoring$Coronate.TieBreak.encode)
              ],
              [
                "roundList",
                Data_Rounds$Coronate.encode(data.roundList)
              ],
              [
                "scoreAdjustments",
                Belt_Array.map(Belt_Map.toArray(data.scoreAdjustments), (function (param) {
                        return [
                                Data_Id$Coronate.encode(param[0]),
                                param[1]
                              ];
                      }))
              ]
            ]);
}

export {
  make ,
  encode ,
  decode ,
  
}
/* Data_Id-Coronate Not a pure module */
