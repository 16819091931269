// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Nanoid from "nanoid";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function toString(x) {
  return x;
}

function fromString(x) {
  return x;
}

var dummy = "________DUMMY________";

function isDummy(id) {
  return id === dummy;
}

function random(prim) {
  return Nanoid.nanoid();
}

function encode(prim) {
  return prim;
}

function decode(json) {
  return Belt_Option.getExn(Js_json.decodeString(json));
}

var compare = Caml.caml_string_compare;

function eq(a, b) {
  return a === b;
}

var Cmp = Belt_Id.comparable(compare);

function fromStringArray(arr) {
  return Belt_Map.fromArray(arr, Cmp);
}

var $$Map = {
  fromStringArray: fromStringArray,
  toStringArray: Belt_Map.toArray
};

var $$Set = {};

function make(a, b) {
  var match = Caml.caml_string_compare(a, b);
  if (match !== 0) {
    if (match !== 1) {
      return [
              b,
              a
            ];
    } else {
      return [
              a,
              b
            ];
    }
  }
  
}

function compare$1(param, param$1) {
  var x = Caml.caml_string_compare(param[0], param$1[0]);
  if (x !== 0) {
    return x;
  }
  var x$1 = Caml.caml_string_compare(param[1], param$1[1]);
  if (x$1 !== 0) {
    return x$1;
  } else {
    return 0;
  }
}

function has(param, id) {
  if (param[0] === id) {
    return true;
  } else {
    return param[1] === id;
  }
}

function toTuple(t) {
  return t;
}

function decode$1(json) {
  var arr = Js_json.decodeArray(json);
  var a = Belt_Option.getExn(Belt_Option.flatMap(arr, (function (arr) {
              return Belt_Array.get(arr, 0);
            })));
  var b = Belt_Option.getExn(Belt_Option.flatMap(arr, (function (arr) {
              return Belt_Array.get(arr, 1);
            })));
  return [
          Belt_Option.getExn(Js_json.decodeString(a)),
          Belt_Option.getExn(Js_json.decodeString(b))
        ];
}

function encode$1(param) {
  return [
          param[0],
          param[1]
        ];
}

var Cmp$1 = Belt_Id.comparable(compare$1);

function decode$2(json) {
  return Belt_Set.fromArray(Belt_Array.map(Belt_Option.getExn(Js_json.decodeArray(json)), decode$1), Cmp$1);
}

function encode$2(data) {
  return Belt_Array.map(Belt_Set.toArray(data), encode$1);
}

function toMapReducer(acc, param) {
  var id2 = param[1];
  var id1 = param[0];
  var s1 = Belt_Set.add(Belt_Set.make(Cmp), id2);
  var s2 = Belt_Set.add(Belt_Set.make(Cmp), id1);
  return Belt_Map.update(Belt_Map.update(acc, id1, (function (s) {
                    if (s !== undefined) {
                      return Caml_option.some(Belt_Set.union(Caml_option.valFromOption(s), s1));
                    } else {
                      return Caml_option.some(s1);
                    }
                  })), id2, (function (s) {
                if (s !== undefined) {
                  return Caml_option.some(Belt_Set.union(Caml_option.valFromOption(s), s2));
                } else {
                  return Caml_option.some(s2);
                }
              }));
}

function toMap(x) {
  return Belt_Set.reduce(x, Belt_Map.make(Cmp), toMapReducer);
}

var id = Cmp;

var Pair_Set = {
  encode: encode$2,
  decode: decode$2,
  toMap: toMap
};

var Pair = {
  make: make,
  has: has,
  toTuple: toTuple,
  id: Cmp$1,
  Id_Set: undefined,
  $$Set: Pair_Set
};

export {
  toString ,
  fromString ,
  dummy ,
  isDummy ,
  random ,
  encode ,
  decode ,
  compare ,
  eq ,
  id ,
  $$Map ,
  $$Set ,
  Pair ,
  
}
/* Cmp Not a pure module */
