// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.bs.js";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Db$Coronate from "../Db.bs.js";
import * as Hooks$Coronate from "../Hooks.bs.js";
import * as Window$Coronate from "../Window.bs.js";
import * as Data_Id$Coronate from "../Data/Data_Id.bs.js";
import * as Data_Player$Coronate from "../Data/Data_Player.bs.js";
import * as Data_Rounds$Coronate from "../Data/Data_Rounds.bs.js";
import * as Data_Tournament$Coronate from "../Data/Data_Tournament.bs.js";
import * as LocalForage_Map$Coronate from "../Externals/LocalForage_Map.bs.js";

function log2(num) {
  return Math.log(num) / Math.log(2.0);
}

function calcNumOfRounds(playerCount) {
  var roundCount = Math.ceil(log2(playerCount));
  if (roundCount !== Pervasives.neg_infinity) {
    return roundCount | 0;
  } else {
    return 0;
  }
}

var emptyTourney = Data_Tournament$Coronate.make(Data_Id$Coronate.random(undefined), "");

function tournamentReducer(param, action) {
  return action;
}

function LoadTournament(Props) {
  var children = Props.children;
  var tourneyId = Props.tourneyId;
  var windowDispatchOpt = Props.windowDispatch;
  var windowDispatch = windowDispatchOpt !== undefined ? windowDispatchOpt : (function (param) {
        
      });
  var tourneyId$1 = Data_Id$Coronate.toString(tourneyId);
  var match = React.useReducer(tournamentReducer, emptyTourney);
  var tourney = match[0];
  var roundList = tourney.roundList;
  var playerIds = tourney.playerIds;
  var name = tourney.name;
  var setTourney = match[1];
  var match$1 = Db$Coronate.useAllPlayers(undefined);
  var arePlayersLoaded = match$1.loaded;
  var players = match$1.items;
  var match$2 = React.useState(function () {
        return /* NotLoaded */0;
      });
  var setTourneyLoaded = match$2[1];
  var tourneyLoaded = match$2[0];
  Hooks$Coronate.useLoadingCursorUntil(tourneyLoaded !== 0 && arePlayersLoaded);
  React.useEffect((function () {
          Curry._1(windowDispatch, {
                TAG: /* SetTitle */2,
                _0: name
              });
          return (function (param) {
                    return Curry._1(windowDispatch, {
                                TAG: /* SetTitle */2,
                                _0: ""
                              });
                  });
        }), [
        name,
        windowDispatch
      ]);
  React.useEffect((function () {
          var didCancel = {
            contents: false
          };
          $$Promise.$$catch(LocalForage_Map$Coronate.getItem(Db$Coronate.tournaments, tourneyId$1).then(function (value) {
                    if (didCancel.contents) {
                      return ;
                    } else if (value !== undefined) {
                      Curry._1(setTourney, value);
                      return Curry._1(setTourneyLoaded, (function (param) {
                                    return /* Loaded */1;
                                  }));
                    } else {
                      return Curry._1(setTourneyLoaded, (function (param) {
                                    return /* Error */2;
                                  }));
                    }
                  }), (function (param) {
                  if (!didCancel.contents) {
                    Curry._1(setTourneyLoaded, (function (param) {
                            return /* Error */2;
                          }));
                  }
                  return Promise.resolve(undefined);
                }));
          return (function (param) {
                    didCancel.contents = true;
                    
                  });
        }), [tourneyId$1]);
  React.useEffect((function () {
          if (tourneyLoaded !== 1 || !Data_Id$Coronate.eq(Data_Id$Coronate.fromString(tourneyId$1), tourney.id)) {
            
          } else {
            LocalForage_Map$Coronate.setItem(Db$Coronate.tournaments, tourneyId$1, tourney);
          }
          
        }), [
        tourneyLoaded,
        tourneyId$1,
        tourney
      ]);
  switch (tourneyLoaded) {
    case /* NotLoaded */0 :
        break;
    case /* Loaded */1 :
        if (arePlayersLoaded) {
          var activePlayers = Belt_Map.keep(players, (function (id, param) {
                  return Belt_Set.has(playerIds, id);
                }));
          var roundCount = calcNumOfRounds(Belt_Map.size(activePlayers));
          var isItOver = Data_Rounds$Coronate.size(roundList) >= roundCount;
          var isNewRoundReady = Data_Rounds$Coronate.size(roundList) === 0 ? true : Data_Rounds$Coronate.isRoundComplete(roundList, activePlayers, Data_Rounds$Coronate.size(roundList) - 1 | 0);
          return Curry._1(children, {
                      activePlayers: activePlayers,
                      getPlayer: (function (param) {
                          return Data_Player$Coronate.getMaybe(players, param);
                        }),
                      isItOver: isItOver,
                      isNewRoundReady: isNewRoundReady,
                      players: players,
                      playersDispatch: match$1.dispatch,
                      roundCount: roundCount,
                      tourney: tourney,
                      setTourney: setTourney
                    });
        }
        break;
    case /* Error */2 :
        return React.createElement(Window$Coronate.Body.make, {
                    children: "Error: tournament couldn't be loaded.",
                    windowDispatch: windowDispatch
                  });
    
  }
  return React.createElement(Window$Coronate.Body.make, {
              children: "Loading...",
              windowDispatch: windowDispatch
            });
}

var make = LoadTournament;

export {
  make ,
  
}
/* emptyTourney Not a pure module */
