// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import Numeral from "numeral";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Db$Coronate from "../Db.bs.js";
import * as ReactFeather from "react-feather";
import * as Hooks$Coronate from "../Hooks.bs.js";
import * as Utils$Coronate from "../Utils.bs.js";
import * as Data_Id$Coronate from "../Data/Data_Id.bs.js";
import * as Belt_MutableQueue from "rescript/lib/es6/belt_MutableQueue.js";
import * as Externals$Coronate from "../Externals/Externals.bs.js";
import * as Data_Match$Coronate from "../Data/Data_Match.bs.js";
import * as Data_Player$Coronate from "../Data/Data_Player.bs.js";
import * as Data_Rounds$Coronate from "../Data/Data_Rounds.bs.js";
import * as HelpDialogs$Coronate from "../HelpDialogs.bs.js";
import * as Data_Pairing$Coronate from "../Data/Data_Pairing.bs.js";
import VisuallyHidden from "@reach/visually-hidden";
import * as TournamentUtils$Coronate from "./TournamentUtils.bs.js";
import * as PageTourneyPlayers$Coronate from "./PageTourneyPlayers.bs.js";

function autoPair(pairData, byeValue, playerMap, byeQueue) {
  var filteredData = Data_Pairing$Coronate.keep(pairData, (function (id, param) {
          return Belt_Map.has(playerMap, id);
        }));
  var match = Data_Pairing$Coronate.setByePlayer(byeQueue, Data_Id$Coronate.dummy, filteredData);
  var byePlayerData = match[1];
  var pairs = Belt_MutableQueue.fromArray(Data_Pairing$Coronate.pairPlayers(match[0]));
  if (byePlayerData !== undefined) {
    Belt_MutableQueue.add(pairs, [
          Data_Pairing$Coronate.id(Caml_option.valFromOption(byePlayerData)),
          Data_Id$Coronate.dummy
        ]);
  }
  return Belt_MutableQueue.map(pairs, (function (param) {
                var blackId = param[1];
                var whiteId = param[0];
                var white = Data_Player$Coronate.getMaybe(playerMap, whiteId);
                var black = Data_Player$Coronate.getMaybe(playerMap, blackId);
                return {
                        id: Data_Id$Coronate.random(undefined),
                        whiteId: whiteId,
                        blackId: blackId,
                        whiteNewRating: white.rating,
                        blackNewRating: black.rating,
                        whiteOrigRating: white.rating,
                        blackOrigRating: black.rating,
                        result: Data_Match$Coronate.Result.scoreByeMatch(whiteId, blackId, byeValue, /* NotSet */3)
                      };
              }));
}

function numFromPair(a, b) {
  if (a !== undefined) {
    if (b !== undefined) {
      return /* Two */2;
    } else {
      return /* One */1;
    }
  } else if (b !== undefined) {
    return /* One */1;
  } else {
    return /* Zero */0;
  }
}

function validateResult(a, b, byeValue, $$default) {
  if (a !== undefined && b !== undefined) {
    return Data_Match$Coronate.Result.scoreByeMatch(Caml_option.valFromOption(a), Caml_option.valFromOption(b), byeValue, $$default);
  } else {
    return /* NotSet */3;
  }
}

function reducer(state, action) {
  var result = state.result;
  var p2 = state.p2;
  var p1 = state.p1;
  if (typeof action === "number") {
    switch (action) {
      case /* RemoveP1 */0 :
          return {
                  p1: undefined,
                  p2: state.p2,
                  result: /* NotSet */3,
                  num: numFromPair(undefined, p2),
                  byeValue: state.byeValue
                };
      case /* RemoveP2 */1 :
          return {
                  p1: state.p1,
                  p2: undefined,
                  result: /* NotSet */3,
                  num: numFromPair(p1, undefined),
                  byeValue: state.byeValue
                };
      case /* Reverse */2 :
          return {
                  p1: p2,
                  p2: p1,
                  result: Data_Match$Coronate.Result.reverse(result),
                  num: state.num,
                  byeValue: state.byeValue
                };
      case /* Clear */3 :
          return {
                  p1: undefined,
                  p2: undefined,
                  result: /* NotSet */3,
                  num: /* Zero */0,
                  byeValue: state.byeValue
                };
      
    }
  } else {
    if (action.TAG !== /* Add */0) {
      return {
              p1: state.p1,
              p2: state.p2,
              result: action._0,
              num: state.num,
              byeValue: state.byeValue
            };
    }
    var id = action._0;
    var match = p1 !== undefined ? (
        p2 !== undefined ? [
            p1,
            p2
          ] : [
            p1,
            Caml_option.some(id)
          ]
      ) : [
        Caml_option.some(id),
        p2
      ];
    var p2$1 = match[1];
    var p1$1 = match[0];
    return {
            p1: p1$1,
            p2: p2$1,
            result: validateResult(p1$1, p2$1, state.byeValue, result),
            num: numFromPair(p1$1, p2$1),
            byeValue: state.byeValue
          };
  }
}

function useStageState(byeValue) {
  return React.useReducer(reducer, {
              p1: undefined,
              p2: undefined,
              result: /* NotSet */3,
              num: /* Zero */0,
              byeValue: byeValue
            });
}

var sortByName = {
  TAG: /* GetString */0,
  _0: (function (x) {
      return x.player.firstName;
    })
};

var sortByIdeal = {
  TAG: /* GetFloat */2,
  _0: (function (x) {
      return x.ideal;
    })
};

function isPlayerSelectable(state, id) {
  var match = state.p1;
  var match$1 = state.p2;
  if (match !== undefined) {
    if (match$1 !== undefined) {
      return false;
    } else {
      return !Data_Id$Coronate.eq(Caml_option.valFromOption(match), id);
    }
  } else if (match$1 !== undefined) {
    return !Data_Id$Coronate.eq(Caml_option.valFromOption(match$1), id);
  } else {
    return true;
  }
}

function PairPicker$SelectPlayerRow(Props) {
  var player = Props.player;
  var ideal = Props.ideal;
  var state = Props.state;
  var dispatch = Props.dispatch;
  var match = state.num;
  return React.createElement("tr", {
              className: Data_Player$Coronate.Type.toString(player.type_)
            }, React.createElement("td", undefined, React.createElement("button", {
                      className: "button-ghost",
                      disabled: !isPlayerSelectable(state, player.id),
                      onClick: (function (param) {
                          return Curry._1(dispatch, {
                                      TAG: /* Add */0,
                                      _0: player.id
                                    });
                        })
                    }, React.createElement(ReactFeather.UserPlus, {}), React.createElement(VisuallyHidden, {
                          children: "Add " + Data_Player$Coronate.fullName(player)
                        }))), React.createElement("td", {
                  className: "pageround__selectlist-name"
                }, Data_Player$Coronate.fullName(player)), React.createElement("td", undefined, match !== 1 ? "-" : Numeral(ideal).format("%")));
}

function PairPicker$SelectList(Props) {
  var pairData = Props.pairData;
  var state = Props.state;
  var dispatch = Props.dispatch;
  var unmatched = Props.unmatched;
  var initialTable = Belt_Array.map(Belt_Map.valuesToArray(unmatched), (function (player) {
          return {
                  player: player,
                  ideal: 0.0
                };
        }));
  var match = Hooks$Coronate.useSortedTable(initialTable, sortByName, false);
  var sortedDispatch = match[1];
  var sorted = match[0];
  React.useEffect((function () {
          var calcIdealOrNot = function (playerId) {
            var match = state.p1;
            var match$1 = state.p2;
            var selectedPlayer;
            if (match !== undefined) {
              if (match$1 !== undefined) {
                return 0;
              }
              selectedPlayer = Caml_option.valFromOption(match);
            } else {
              if (match$1 === undefined) {
                return 0;
              }
              selectedPlayer = Caml_option.valFromOption(match$1);
            }
            var ideal = Data_Pairing$Coronate.calcPairIdealByIds(pairData, selectedPlayer, playerId);
            if (ideal !== undefined) {
              return ideal / Data_Pairing$Coronate.maxPriority(pairData);
            } else {
              return 0;
            }
          };
          var table = Belt_Array.map(Belt_Map.valuesToArray(unmatched), (function (player) {
                  return {
                          player: player,
                          ideal: calcIdealOrNot(player.id)
                        };
                }));
          Curry._1(sortedDispatch, {
                TAG: /* SetTable */2,
                _0: table
              });
          
        }), [
        unmatched,
        pairData,
        sortedDispatch,
        state
      ]);
  if (Belt_Map.size(unmatched) === 0) {
    return null;
  } else {
    return React.createElement("table", {
                className: "content pageround__select-list"
              }, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", undefined, React.createElement(VisuallyHidden, {
                                children: "Controls"
                              })), React.createElement("th", undefined, React.createElement(Hooks$Coronate.SortButton.make, {
                                children: "Name",
                                sortColumn: sortByName,
                                data: sorted,
                                dispatch: sortedDispatch
                              })), React.createElement("th", undefined, React.createElement(Hooks$Coronate.SortButton.make, {
                                children: "Ideal",
                                sortColumn: sortByIdeal,
                                data: sorted,
                                dispatch: sortedDispatch
                              })))), React.createElement("tbody", undefined, Belt_Array.map(sorted.table, (function (param) {
                          var player = param.player;
                          return React.createElement(PairPicker$SelectPlayerRow, {
                                      player: player,
                                      ideal: param.ideal,
                                      state: state,
                                      dispatch: dispatch,
                                      key: Data_Id$Coronate.toString(player.id)
                                    });
                        })), React.createElement(PairPicker$SelectPlayerRow, {
                        player: Data_Player$Coronate.dummy,
                        ideal: 0.0,
                        state: state,
                        dispatch: dispatch
                      })));
  }
}

function PairPicker$Stage(Props) {
  var getPlayer = Props.getPlayer;
  var pairData = Props.pairData;
  var roundId = Props.roundId;
  var state = Props.state;
  var dispatch = Props.dispatch;
  var setTourney = Props.setTourney;
  var byeValue = Props.byeValue;
  var tourney = Props.tourney;
  var round = Props.round;
  var roundList = tourney.roundList;
  var player = state.p1;
  var whiteName = player !== undefined ? Data_Player$Coronate.fullName(Curry._1(getPlayer, Caml_option.valFromOption(player))) : "";
  var player$1 = state.p2;
  var blackName = player$1 !== undefined ? Data_Player$Coronate.fullName(Curry._1(getPlayer, Caml_option.valFromOption(player$1))) : "";
  var match = function (param) {
    var match$1 = state.p1;
    var match$2 = state.p2;
    if (match$1 === undefined) {
      return ;
    }
    if (match$2 === undefined) {
      return ;
    }
    var newRound = Curry._2(Data_Rounds$Coronate.Round.addMatches, round, [Data_Match$Coronate.manualPair(Curry._1(getPlayer, Caml_option.valFromOption(match$1)), Curry._1(getPlayer, Caml_option.valFromOption(match$2)), state.result, byeValue)]);
    var roundList$1 = Data_Rounds$Coronate.set(roundList, roundId, newRound);
    if (roundList$1 !== undefined) {
      Curry._1(setTourney, {
            id: tourney.id,
            name: tourney.name,
            date: tourney.date,
            playerIds: tourney.playerIds,
            scoreAdjustments: tourney.scoreAdjustments,
            byeQueue: tourney.byeQueue,
            tieBreaks: tourney.tieBreaks,
            roundList: Caml_option.valFromOption(roundList$1)
          });
    } else {
      console.error("Couldn't add round " + String(roundId));
    }
    return Curry._1(dispatch, /* Clear */3);
  };
  var match$1 = state.p1;
  var match$2 = state.p2;
  var matchIdeal;
  if (match$1 !== undefined && match$2 !== undefined) {
    var ideal = Data_Pairing$Coronate.calcPairIdealByIds(pairData, Caml_option.valFromOption(match$1), Caml_option.valFromOption(match$2));
    matchIdeal = ideal !== undefined ? Numeral(ideal / Data_Pairing$Coronate.maxPriority(pairData)).format("%") : null;
  } else {
    matchIdeal = null;
  }
  var p = state.p1;
  var p$1 = state.p2;
  return React.createElement("div", undefined, React.createElement("h2", undefined, "Selected for matching:"), React.createElement("div", {
                  className: "content"
                }, React.createElement("p", undefined, "White: ", p !== undefined ? React.createElement(React.Fragment, undefined, React.createElement("span", {
                                className: Data_Player$Coronate.Type.toString(Curry._1(getPlayer, Caml_option.valFromOption(p)).type_)
                              }, whiteName + " "), React.createElement("button", {
                                className: "button-micro button-ghost",
                                onClick: (function (param) {
                                    return Curry._1(dispatch, /* RemoveP1 */0);
                                  })
                              }, React.createElement(ReactFeather.UserMinus, {}), React.createElement(VisuallyHidden, {
                                    children: " Remove " + whiteName
                                  }))) : null), React.createElement("p", undefined, "Black: ", p$1 !== undefined ? React.createElement(React.Fragment, undefined, React.createElement("span", {
                                className: Data_Player$Coronate.Type.toString(Curry._1(getPlayer, Caml_option.valFromOption(p$1)).type_)
                              }, blackName + " "), React.createElement("button", {
                                className: "button-micro button-ghost",
                                onClick: (function (param) {
                                    return Curry._1(dispatch, /* RemoveP2 */1);
                                  })
                              }, React.createElement(ReactFeather.UserMinus, {}), React.createElement(VisuallyHidden, {
                                    children: " Remove " + blackName
                                  }))) : null), React.createElement("p", undefined, "Match ideal: ", matchIdeal)), React.createElement("p", undefined, React.createElement("label", undefined, "Pre-select winner ", React.createElement(Utils$Coronate.TestId.make, {
                          children: React.createElement("select", {
                                disabled: state.num !== /* Two */2,
                                value: Data_Match$Coronate.Result.toString(state.result),
                                onBlur: (function (e) {
                                    return Curry._1(dispatch, {
                                                TAG: /* SetResult */1,
                                                _0: Data_Match$Coronate.Result.fromString(e.target.value)
                                              });
                                  }),
                                onChange: (function (e) {
                                    return Curry._1(dispatch, {
                                                TAG: /* SetResult */1,
                                                _0: Data_Match$Coronate.Result.fromString(e.target.value)
                                              });
                                  })
                              }, React.createElement("option", {
                                    value: Data_Match$Coronate.Result.toString(/* NotSet */3)
                                  }, "None"), React.createElement("option", {
                                    value: Data_Match$Coronate.Result.toString(/* WhiteWon */0)
                                  }, "White won"), React.createElement("option", {
                                    value: Data_Match$Coronate.Result.toString(/* BlackWon */1)
                                  }, "Black won"), React.createElement("option", {
                                    value: Data_Match$Coronate.Result.toString(/* Draw */2)
                                  }, "Draw")),
                          testId: "pairpicker-preselect-winner"
                        }))), React.createElement("div", {
                  className: "toolbar"
                }, React.createElement("button", {
                      disabled: state.num === /* Zero */0,
                      onClick: (function (param) {
                          return Curry._1(dispatch, /* Reverse */2);
                        })
                    }, React.createElement(ReactFeather.Repeat, {}), " Swap colors"), " ", React.createElement("button", {
                      className: "button-primary",
                      disabled: state.num !== /* Two */2,
                      onClick: match
                    }, React.createElement(ReactFeather.Check, {}), " Match selected")));
}

function PairPicker$PlayerInfo(Props) {
  var player = Props.player;
  var scoreData = Props.scoreData;
  var players = Props.players;
  var avoidPairs = Props.avoidPairs;
  var origRating = Props.origRating;
  var newRating = Props.newRating;
  var getPlayer = Props.getPlayer;
  var match = TournamentUtils$Coronate.getScoreInfo(player, scoreData, avoidPairs, getPlayer, players, origRating, newRating);
  var player$1 = match.player;
  return React.createElement("div", {
              className: "player-card"
            }, React.createElement("h3", undefined, Data_Player$Coronate.fullName(player$1)), React.createElement("dl", undefined, React.createElement("dt", undefined, "Score"), React.createElement("dd", undefined, match.score), React.createElement("dt", {
                      id: "rating-" + Data_Id$Coronate.toString(player$1.id)
                    }), React.createElement("dt", undefined, "Rating"), React.createElement("dd", undefined, match.rating), React.createElement("dt", undefined, "Color balance"), React.createElement("dd", undefined, match.colorBalance), React.createElement("dt", undefined, "Has had a bye round"), React.createElement("dd", undefined, match.hasBye ? "Yes" : "No"), React.createElement("dt", undefined, "Opponent history"), React.createElement("dd", {
                      style: {
                        margin: "0"
                      }
                    }, React.createElement("ol", undefined, match.opponentResults)), React.createElement("dt", undefined, "Players to avoid"), React.createElement("dd", {
                      style: {
                        margin: "0"
                      }
                    }, React.createElement("ul", undefined, match.avoidListHtml))));
}

function PairPicker(Props) {
  var roundId = Props.roundId;
  var tournament = Props.tournament;
  var scoreData = Props.scoreData;
  var unmatched = Props.unmatched;
  var unmatchedWithDummy = Props.unmatchedWithDummy;
  var match = Db$Coronate.useConfig(undefined);
  var match$1 = match[0];
  var byeValue = match$1.byeValue;
  var avoidPairs = match$1.avoidPairs;
  var match$2 = useStageState(byeValue);
  var tourney = tournament.tourney;
  var roundList = tourney.roundList;
  var byeQueue = tourney.byeQueue;
  var setTourney = tournament.setTourney;
  var players = tournament.players;
  var getPlayer = tournament.getPlayer;
  var activePlayers = tournament.activePlayers;
  var dispatch = match$2[1];
  var state = match$2[0];
  var round = Data_Rounds$Coronate.get(roundList, roundId);
  var addOrRemovePlayers = Hooks$Coronate.useBool(false);
  var autoPairHelp = Hooks$Coronate.useBool(false);
  var pairData = React.useMemo((function () {
          return Data_Pairing$Coronate.make(scoreData, activePlayers, avoidPairs);
        }), [
        activePlayers,
        avoidPairs,
        scoreData
      ]);
  React.useEffect((function () {
          var p1 = state.p1;
          if (p1 !== undefined) {
            var match = Belt_Map.get(unmatchedWithDummy, Caml_option.valFromOption(p1));
            if (match !== undefined) {
              
            } else {
              Curry._1(dispatch, /* RemoveP1 */0);
            }
          }
          var p2 = state.p2;
          if (p2 !== undefined) {
            var match$1 = Belt_Map.get(unmatchedWithDummy, Caml_option.valFromOption(p2));
            if (match$1 !== undefined) {
              
            } else {
              Curry._1(dispatch, /* RemoveP2 */1);
            }
          }
          
        }), [
        unmatchedWithDummy,
        state,
        dispatch
      ]);
  if (round === undefined) {
    return React.createElement("div", undefined, "No round available.");
  }
  var round$1 = Caml_option.valFromOption(round);
  return React.createElement("div", {
              className: "content-area"
            }, React.createElement(Utils$Coronate.PanelContainer.make, {
                  children: null
                }, React.createElement(Utils$Coronate.Panel.make, {
                      children: null
                    }, React.createElement("div", {
                          className: "toolbar"
                        }, React.createElement("button", {
                              className: "button-primary",
                              disabled: Belt_Map.size(unmatched) === 0,
                              onClick: (function (param) {
                                  var newRound = Curry._2(Data_Rounds$Coronate.Round.addMatches, round$1, Belt_MutableQueue.toArray(autoPair(pairData, byeValue, unmatched, byeQueue)));
                                  var roundList$1 = Data_Rounds$Coronate.set(roundList, roundId, newRound);
                                  if (roundList$1 !== undefined) {
                                    return Curry._1(setTourney, {
                                                id: tourney.id,
                                                name: tourney.name,
                                                date: tourney.date,
                                                playerIds: tourney.playerIds,
                                                scoreAdjustments: tourney.scoreAdjustments,
                                                byeQueue: tourney.byeQueue,
                                                tieBreaks: tourney.tieBreaks,
                                                roundList: Caml_option.valFromOption(roundList$1)
                                              });
                                  }
                                  
                                })
                            }, "Auto-pair unmatched players"), React.createElement("button", {
                              className: "button-ghost",
                              onClick: (function (param) {
                                  return Curry._1(autoPairHelp.setTrue, undefined);
                                })
                            }, React.createElement(ReactFeather.HelpCircle, {}), React.createElement(VisuallyHidden, {
                                  children: "Auto-pair information."
                                }))), React.createElement(PairPicker$SelectList, {
                          pairData: pairData,
                          state: state,
                          dispatch: dispatch,
                          unmatched: unmatched
                        }), React.createElement("div", {
                          className: "toolbar"
                        }, React.createElement("button", {
                              onClick: (function (param) {
                                  return Curry._1(addOrRemovePlayers.setTrue, undefined);
                                })
                            }, "Add or remove players from the roster."))), React.createElement(Utils$Coronate.Panel.make, {
                      children: null,
                      style: {
                        flexGrow: "1"
                      }
                    }, React.createElement(PairPicker$Stage, {
                          getPlayer: getPlayer,
                          pairData: pairData,
                          roundId: roundId,
                          state: state,
                          dispatch: dispatch,
                          setTourney: setTourney,
                          byeValue: byeValue,
                          tourney: tourney,
                          round: round$1
                        }), React.createElement(Utils$Coronate.PanelContainer.make, {
                          children: Belt_Array.map([
                                state.p1,
                                state.p2
                              ], (function (id) {
                                  if (id === undefined) {
                                    return null;
                                  }
                                  var playerId = Caml_option.valFromOption(id);
                                  return React.createElement(Utils$Coronate.Panel.make, {
                                              children: React.createElement(PairPicker$PlayerInfo, {
                                                    player: Curry._1(getPlayer, playerId),
                                                    scoreData: scoreData,
                                                    players: players,
                                                    avoidPairs: avoidPairs,
                                                    origRating: Curry._1(getPlayer, playerId).rating,
                                                    newRating: undefined,
                                                    getPlayer: getPlayer
                                                  }),
                                              key: Data_Id$Coronate.toString(playerId)
                                            });
                                }))
                        }))), React.createElement(Externals$Coronate.Dialog.make, {
                  isOpen: addOrRemovePlayers.state,
                  onDismiss: addOrRemovePlayers.setFalse,
                  ariaLabel: "Select players",
                  children: null,
                  className: ""
                }, React.createElement("button", {
                      className: "button-micro",
                      onClick: (function (param) {
                          return Curry._1(addOrRemovePlayers.setFalse, undefined);
                        })
                    }, "Done"), React.createElement(PageTourneyPlayers$Coronate.Selecting.make, {
                      tourney: tourney,
                      setTourney: setTourney,
                      players: players,
                      playersDispatch: tournament.playersDispatch
                    })), React.createElement(HelpDialogs$Coronate.Pairing.make, {
                  state: autoPairHelp,
                  ariaLabel: "Auto-pair information"
                }));
}

var make = PairPicker;

export {
  make ,
  
}
/* react Not a pure module */
