// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Float from "rescript/lib/es6/belt_Float.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactFeather from "react-feather";
import * as Hooks$Coronate from "../Hooks.bs.js";
import * as Utils$Coronate from "../Utils.bs.js";
import * as Data_Id$Coronate from "../Data/Data_Id.bs.js";
import * as Belt_MutableQueue from "rescript/lib/es6/belt_MutableQueue.js";
import * as Externals$Coronate from "../Externals/Externals.bs.js";
import * as Data_Player$Coronate from "../Data/Data_Player.bs.js";
import * as Data_Rounds$Coronate from "../Data/Data_Rounds.bs.js";
import * as PagePlayers$Coronate from "../PagePlayers.bs.js";
import VisuallyHidden from "@reach/visually-hidden";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";

function PageTourneyPlayers$Selecting(Props) {
  var tourney = Props.tourney;
  var setTourney = Props.setTourney;
  var players = Props.players;
  var playersDispatch = Props.playersDispatch;
  var playerIds = tourney.playerIds;
  var togglePlayer = function ($$event) {
    var id = $$event.target.value;
    if ($$event.target.checked) {
      return Curry._1(setTourney, {
                  id: tourney.id,
                  name: tourney.name,
                  date: tourney.date,
                  playerIds: Belt_Set.add(playerIds, id),
                  scoreAdjustments: tourney.scoreAdjustments,
                  byeQueue: tourney.byeQueue,
                  tieBreaks: tourney.tieBreaks,
                  roundList: tourney.roundList
                });
    } else {
      return Curry._1(setTourney, {
                  id: tourney.id,
                  name: tourney.name,
                  date: tourney.date,
                  playerIds: Belt_Set.keep(playerIds, (function (pId) {
                          return !Data_Id$Coronate.eq(pId, id);
                        })),
                  scoreAdjustments: tourney.scoreAdjustments,
                  byeQueue: tourney.byeQueue,
                  tieBreaks: tourney.tieBreaks,
                  roundList: tourney.roundList
                });
    }
  };
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "toolbar"
                }, React.createElement("button", {
                      className: "button-micro",
                      onClick: (function (param) {
                          return Curry._1(setTourney, {
                                      id: tourney.id,
                                      name: tourney.name,
                                      date: tourney.date,
                                      playerIds: Belt_Set.fromArray(Belt_Map.keysToArray(players), Data_Id$Coronate.id),
                                      scoreAdjustments: tourney.scoreAdjustments,
                                      byeQueue: tourney.byeQueue,
                                      tieBreaks: tourney.tieBreaks,
                                      roundList: tourney.roundList
                                    });
                        })
                    }, "Select all"), React.createElement("button", {
                      className: "button-micro",
                      onClick: (function (param) {
                          return Curry._1(setTourney, {
                                      id: tourney.id,
                                      name: tourney.name,
                                      date: tourney.date,
                                      playerIds: Belt_Set.make(Data_Id$Coronate.id),
                                      scoreAdjustments: tourney.scoreAdjustments,
                                      byeQueue: tourney.byeQueue,
                                      tieBreaks: tourney.tieBreaks,
                                      roundList: tourney.roundList
                                    });
                        })
                    }, "Select none")), React.createElement("table", undefined, React.createElement("caption", undefined, "Select players"), React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", undefined, "First name"), React.createElement("th", undefined, "Last name"), React.createElement("th", undefined, "Select"))), React.createElement("tbody", undefined, Belt_Array.map(Belt_Map.valuesToArray(players), (function (param) {
                            var lastName = param.lastName;
                            var id = param.id;
                            var firstName = param.firstName;
                            return React.createElement("tr", {
                                        key: Data_Id$Coronate.toString(id)
                                      }, React.createElement("td", undefined, firstName), React.createElement("td", undefined, lastName), React.createElement("td", undefined, React.createElement(VisuallyHidden, {
                                                children: React.createElement("label", {
                                                      htmlFor: "select-" + Data_Id$Coronate.toString(id)
                                                    }, "Select " + firstName + " " + lastName)
                                              }), React.createElement("input", {
                                                id: "select-" + Data_Id$Coronate.toString(id),
                                                checked: Belt_Set.has(playerIds, id),
                                                type: "checkbox",
                                                value: Data_Id$Coronate.toString(id),
                                                onChange: togglePlayer
                                              })));
                          })))), React.createElement(PagePlayers$Coronate.NewPlayerForm.make, {
                  dispatch: playersDispatch,
                  addPlayerCallback: (function (id) {
                      return Curry._1(setTourney, {
                                  id: tourney.id,
                                  name: tourney.name,
                                  date: tourney.date,
                                  playerIds: Belt_Set.add(playerIds, id),
                                  scoreAdjustments: tourney.scoreAdjustments,
                                  byeQueue: tourney.byeQueue,
                                  tieBreaks: tourney.tieBreaks,
                                  roundList: tourney.roundList
                                });
                    })
                }));
}

var Selecting = {
  make: PageTourneyPlayers$Selecting
};

function hasHadBye(matches, playerId) {
  return Belt_Array.some(Belt_Array.keep(Belt_MutableQueue.toArray(matches), (function (match) {
                    if (Data_Id$Coronate.eq(match.whiteId, playerId)) {
                      return true;
                    } else {
                      return Data_Id$Coronate.eq(match.blackId, playerId);
                    }
                  })), (function (match) {
                if (Data_Id$Coronate.isDummy(match.whiteId)) {
                  return true;
                } else {
                  return Data_Id$Coronate.isDummy(match.blackId);
                }
              }));
}

function errorNotification(x) {
  if (x !== undefined && x.TAG !== /* Ok */0) {
    return React.createElement(Utils$Coronate.$$Notification.make, {
                children: x._0,
                kind: /* Error */2
              });
  } else {
    return null;
  }
}

var validators = {
  scoreAdjustment: {
    strategy: /* OnFirstSuccessOrFirstBlur */3,
    validate: (function (input) {
        var x = Belt_Float.fromString(input.scoreAdjustment);
        if (x !== undefined) {
          return {
                  TAG: /* Ok */0,
                  _0: x
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: "Score adjustment must be a number."
                };
        }
      })
  }
};

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: {
            scoreAdjustment: /* Pristine */0
          },
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.scoreAdjustment;
  var match$1 = match ? match._0 : Curry._1(validators.scoreAdjustment.validate, input);
  if (match$1.TAG === /* Ok */0) {
    return {
            TAG: /* Valid */0,
            output: {
              scoreAdjustment: match$1._0
            },
            fieldsStatuses: {
              scoreAdjustment: /* Dirty */{
                _0: match$1,
                _1: /* Shown */0
              }
            },
            collectionsStatuses: undefined
          };
  } else {
    return {
            TAG: /* Invalid */1,
            fieldsStatuses: {
              scoreAdjustment: /* Dirty */{
                _0: match$1,
                _1: /* Shown */0
              }
            },
            collectionsStatuses: undefined
          };
  }
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurScoreAdjustmentField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.scoreAdjustment, validators.scoreAdjustment, (function (status) {
                          return {
                                  scoreAdjustment: status
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */1 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              return Curry._2(onSubmit, output, {
                                          notifyOnSuccess: (function (input) {
                                              return Curry._1(dispatch, {
                                                          TAG: /* SetSubmittedStatus */1,
                                                          _0: input
                                                        });
                                            }),
                                          notifyOnFailure: (function (error) {
                                              return Curry._1(dispatch, {
                                                          TAG: /* SetSubmissionFailedStatus */2,
                                                          _0: error
                                                        });
                                            }),
                                          reset: (function (param) {
                                              return Curry._1(dispatch, /* Reset */4);
                                            }),
                                          dismissSubmissionResult: (function (param) {
                                              return Curry._1(dispatch, /* DismissSubmissionResult */3);
                                            })
                                        });
                            })
                        };
                  break;
              case /* DismissSubmissionError */2 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */3 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */4 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateScoreAdjustmentField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.scoreAdjustment, state.submissionStatus, validators.scoreAdjustment, (function (status) {
                                    return {
                                            scoreAdjustment: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */1 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: {
                                scoreAdjustment: /* Pristine */0
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: {
                                scoreAdjustment: /* Pristine */0
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */2 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */3 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Caml_option.some(Curry._1(map, Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateScoreAdjustment: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateScoreAdjustmentField */0,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          blurScoreAdjustment: (function (param) {
              return Curry._1(dispatch, /* BlurScoreAdjustmentField */0);
            }),
          scoreAdjustmentResult: Formality.exposeFieldResult(state.fieldsStatuses.scoreAdjustment),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.scoreAdjustment) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              return Curry._1(dispatch, /* Submit */1);
            }),
          dismissSubmissionError: (function (param) {
              return Curry._1(dispatch, /* DismissSubmissionError */2);
            }),
          dismissSubmissionResult: (function (param) {
              return Curry._1(dispatch, /* DismissSubmissionResult */3);
            }),
          mapSubmissionError: (function (map) {
              return Curry._1(dispatch, {
                          TAG: /* MapSubmissionError */3,
                          _0: map
                        });
            }),
          reset: (function (param) {
              return Curry._1(dispatch, /* Reset */4);
            })
        };
}

function PageTourneyPlayers$OptionsForm$More(Props) {
  var setTourney = Props.setTourney;
  var dialog = Props.dialog;
  var tourney = Props.tourney;
  var p = Props.p;
  var scoreAdjustment = Belt_Option.mapWithDefault(Belt_Map.get(tourney.scoreAdjustments, p.id), "0", (function (prim) {
          return String(prim);
        }));
  var form = useForm({
        scoreAdjustment: scoreAdjustment
      }, (function (output, callback) {
          var scoreAdjustment = output.scoreAdjustment;
          if (scoreAdjustment !== 0) {
            Curry._1(setTourney, {
                  id: tourney.id,
                  name: tourney.name,
                  date: tourney.date,
                  playerIds: tourney.playerIds,
                  scoreAdjustments: Belt_Map.set(tourney.scoreAdjustments, p.id, scoreAdjustment),
                  byeQueue: tourney.byeQueue,
                  tieBreaks: tourney.tieBreaks,
                  roundList: tourney.roundList
                });
          } else {
            Curry._1(setTourney, {
                  id: tourney.id,
                  name: tourney.name,
                  date: tourney.date,
                  playerIds: tourney.playerIds,
                  scoreAdjustments: Belt_Map.remove(tourney.scoreAdjustments, p.id),
                  byeQueue: tourney.byeQueue,
                  tieBreaks: tourney.tieBreaks,
                  roundList: tourney.roundList
                });
          }
          Curry._1(callback.notifyOnSuccess, undefined);
          return Curry._1(dialog.setFalse, undefined);
        }));
  return React.createElement(React.Fragment, undefined, React.createElement("button", {
                  className: "button-micro button-primary",
                  onClick: (function (param) {
                      return Curry._1(dialog.setFalse, undefined);
                    })
                }, "close"), React.createElement("h2", undefined, "Options for " + Data_Player$Coronate.fullName(p)), React.createElement("form", {
                  onSubmit: (function ($$event) {
                      $$event.preventDefault();
                      return Curry._1(form.submit, undefined);
                    })
                }, React.createElement("h3", undefined, React.createElement("label", {
                          className: "title-30",
                          htmlFor: Data_Id$Coronate.toString(p.id) + "-scoreAdjustment"
                        }, "Score adjustment")), React.createElement("p", {
                      className: "caption-30",
                      id: Data_Id$Coronate.toString(p.id) + "-scoreAdjustment-description"
                    }, "Score adjustment will be added to this player's actual score.\n              It can be negative."), React.createElement("p", undefined, React.createElement("input", {
                          "aria-describedby": Data_Id$Coronate.toString(p.id) + "-scoreAdjustment-description",
                          id: Data_Id$Coronate.toString(p.id) + "-scoreAdjustment",
                          disabled: form.submitting,
                          size: 3,
                          step: 0.5,
                          type: "number",
                          value: form.input.scoreAdjustment,
                          onBlur: (function (param) {
                              return Curry._1(form.blurScoreAdjustment, undefined);
                            }),
                          onChange: (function ($$event) {
                              return Curry._2(form.updateScoreAdjustment, (function (_input, value) {
                                            return {
                                                    scoreAdjustment: value
                                                  };
                                          }), $$event.target.value);
                            })
                        }), " ", React.createElement("button", {
                          className: "button-micro",
                          onClick: (function ($$event) {
                              $$event.preventDefault();
                              return Curry._2(form.updateScoreAdjustment, (function (_input, value) {
                                            return {
                                                    scoreAdjustment: value
                                                  };
                                          }), "0");
                            })
                        }, "Reset")), errorNotification(form.scoreAdjustmentResult), React.createElement("p", undefined, React.createElement("input", {
                          disabled: form.submitting || !Curry._1(form.valid, undefined),
                          type: "submit",
                          value: "Save"
                        }))));
}

function PageTourneyPlayers$OptionsForm(Props) {
  var setTourney = Props.setTourney;
  var tourney = Props.tourney;
  var byeQueue = Props.byeQueue;
  var p = Props.p;
  var dialog = Hooks$Coronate.useBool(false);
  return React.createElement(React.Fragment, undefined, React.createElement("button", {
                  className: "button-micro",
                  disabled: byeQueue.includes(p.id),
                  onClick: (function (param) {
                      return Curry._1(setTourney, {
                                  id: tourney.id,
                                  name: tourney.name,
                                  date: tourney.date,
                                  playerIds: tourney.playerIds,
                                  scoreAdjustments: tourney.scoreAdjustments,
                                  byeQueue: Belt_Array.concat(byeQueue, [p.id]),
                                  tieBreaks: tourney.tieBreaks,
                                  roundList: tourney.roundList
                                });
                    })
                }, "Bye signup"), " ", React.createElement("button", {
                  className: "button-micro",
                  onClick: (function (param) {
                      return Curry._1(dialog.setTrue, undefined);
                    })
                }, React.createElement("span", {
                      "aria-hidden": true
                    }, React.createElement(ReactFeather.MoreHorizontal, {})), React.createElement(VisuallyHidden, {
                      children: "More options for " + Data_Player$Coronate.fullName(p)
                    })), React.createElement(Externals$Coronate.Dialog.make, {
                  isOpen: dialog.state,
                  onDismiss: dialog.setFalse,
                  ariaLabel: "Options for " + Data_Player$Coronate.fullName(p),
                  children: React.createElement(PageTourneyPlayers$OptionsForm$More, {
                        setTourney: setTourney,
                        dialog: dialog,
                        tourney: tourney,
                        p: p
                      }),
                  className: ""
                }));
}

function PageTourneyPlayers$PlayerList(Props) {
  var players = Props.players;
  var tourney = Props.tourney;
  var setTourney = Props.setTourney;
  var byeQueue = Props.byeQueue;
  return React.createElement(React.Fragment, undefined, Belt_Array.map(Belt_Map.valuesToArray(players), (function (p) {
                    return React.createElement("tr", {
                                key: Data_Id$Coronate.toString(p.id),
                                className: "player " + Data_Player$Coronate.Type.toString(p.type_)
                              }, React.createElement("td", undefined, Data_Player$Coronate.fullName(p)), React.createElement("td", undefined, React.createElement(PageTourneyPlayers$OptionsForm, {
                                        setTourney: setTourney,
                                        tourney: tourney,
                                        byeQueue: byeQueue,
                                        p: p
                                      })));
                  })));
}

function PageTourneyPlayers(Props) {
  var tournament = Props.tournament;
  var tourney = tournament.tourney;
  var byeQueue = tourney.byeQueue;
  var playerIds = tourney.playerIds;
  var setTourney = tournament.setTourney;
  var getPlayer = tournament.getPlayer;
  var match = React.useState(function () {
        return Belt_Set.isEmpty(playerIds);
      });
  var setIsSelecting = match[1];
  var matches = Data_Rounds$Coronate.rounds2Matches(tourney.roundList);
  return React.createElement("div", {
              className: "content-area"
            }, React.createElement("div", {
                  className: "toolbar"
                }, React.createElement("button", {
                      onClick: (function (param) {
                          return Curry._1(setIsSelecting, (function (param) {
                                        return true;
                                      }));
                        })
                    }, React.createElement(ReactFeather.Edit, {}), " Edit player roster")), React.createElement(Utils$Coronate.PanelContainer.make, {
                  children: null
                }, React.createElement(Utils$Coronate.Panel.make, {
                      children: React.createElement("table", undefined, React.createElement("caption", undefined, "Current roster"), React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", undefined, "Name"), React.createElement("th", undefined, "Options"))), React.createElement("tbody", {
                                className: "content"
                              }, React.createElement(PageTourneyPlayers$PlayerList, {
                                    players: tournament.activePlayers,
                                    tourney: tourney,
                                    setTourney: setTourney,
                                    byeQueue: byeQueue
                                  }))),
                      style: {
                        flexShrink: "0"
                      }
                    }), React.createElement(Utils$Coronate.Panel.make, {
                      children: null
                    }, React.createElement("h3", undefined, "Bye queue"), byeQueue.length !== 0 ? React.createElement("table", {
                            style: {
                              width: "100%"
                            }
                          }, React.createElement("tbody", undefined, Belt_Array.map(byeQueue, (function (pId) {
                                      return React.createElement("tr", {
                                                  key: Data_Id$Coronate.toString(pId),
                                                  className: hasHadBye(matches, pId) ? "disabled" : ""
                                                }, React.createElement("td", undefined, Data_Player$Coronate.fullName(Curry._1(getPlayer, pId))), React.createElement("td", undefined, React.createElement("button", {
                                                          className: "button-micro",
                                                          onClick: (function (param) {
                                                              return Curry._1(setTourney, {
                                                                          id: tourney.id,
                                                                          name: tourney.name,
                                                                          date: tourney.date,
                                                                          playerIds: tourney.playerIds,
                                                                          scoreAdjustments: tourney.scoreAdjustments,
                                                                          byeQueue: byeQueue.filter(function (id) {
                                                                                return !Data_Id$Coronate.eq(pId, id);
                                                                              }),
                                                                          tieBreaks: tourney.tieBreaks,
                                                                          roundList: tourney.roundList
                                                                        });
                                                            })
                                                        }, "Remove")));
                                    })))) : React.createElement("p", {
                            className: "caption-20"
                          }, "No one has signed up yet.")), React.createElement(Externals$Coronate.Dialog.make, {
                      isOpen: match[0],
                      onDismiss: (function (param) {
                          return Curry._1(setIsSelecting, (function (param) {
                                        return false;
                                      }));
                        }),
                      ariaLabel: "Select players",
                      children: null,
                      className: ""
                    }, React.createElement("button", {
                          className: "button-micro button-primary",
                          onClick: (function (param) {
                              return Curry._1(setIsSelecting, (function (param) {
                                            return false;
                                          }));
                            })
                        }, "Done"), React.createElement(PageTourneyPlayers$Selecting, {
                          tourney: tourney,
                          setTourney: setTourney,
                          players: tournament.players,
                          playersDispatch: tournament.playersDispatch
                        }))));
}

var make = PageTourneyPlayers;

export {
  Selecting ,
  make ,
  
}
/* react Not a pure module */
