// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Data_Id$Coronate from "../Data/Data_Id.bs.js";
import * as Data_Config$Coronate from "../Data/Data_Config.bs.js";
import * as Data_Player$Coronate from "../Data/Data_Player.bs.js";
import * as Data_Rounds$Coronate from "../Data/Data_Rounds.bs.js";
import * as Data_Tournament$Coronate from "../Data/Data_Tournament.bs.js";
import FixturePairingAprilJson from "./fixture-pairing-april-2022.json";

var crowTRobot_id = Data_Id$Coronate.fromString("Crow_T_Robot_________");

var crowTRobot = {
  firstName: "Crow T",
  id: crowTRobot_id,
  lastName: "Robot",
  matchCount: 5,
  rating: 1700,
  type_: /* Person */0
};

var drClaytonForrester_id = Data_Id$Coronate.fromString("Dr_Clayton_Forrester_");

var drClaytonForrester = {
  firstName: "Clayton",
  id: drClaytonForrester_id,
  lastName: "Forrester",
  matchCount: 40,
  rating: 2100,
  type_: /* Person */0
};

var grandyMcMaster_id = Data_Id$Coronate.fromString("Grandy_McMaster______");

var grandyMcMaster = {
  firstName: "Grandy",
  id: grandyMcMaster_id,
  lastName: "McMaster",
  matchCount: 100,
  rating: 2600,
  type_: /* Person */0
};

var gypsy_id = Data_Id$Coronate.fromString("Gypsy________________");

var gypsy = {
  firstName: "Gypsy",
  id: gypsy_id,
  lastName: "",
  matchCount: 15,
  rating: 1600,
  type_: /* Person */0
};

var joelRobinson_id = Data_Id$Coronate.fromString("Joel_Robinson________");

var joelRobinson = {
  firstName: "Joel",
  id: joelRobinson_id,
  lastName: "Robinson",
  matchCount: 70,
  rating: 2400,
  type_: /* Person */0
};

var newbieMcNewberson_id = Data_Id$Coronate.fromString("Newbie_McNewberson___");

var newbieMcNewberson = {
  firstName: "Newbie",
  id: newbieMcNewberson_id,
  lastName: "McNewberson",
  matchCount: 0,
  rating: 800,
  type_: /* Person */0
};

var tomServo_id = Data_Id$Coronate.fromString("Tom_Servo____________");

var tomServo = {
  firstName: "Tom",
  id: tomServo_id,
  lastName: "Servo",
  matchCount: 10,
  rating: 1800,
  type_: /* Person */0
};

var tvsFrank_id = Data_Id$Coronate.fromString("TVs_Frank____________");

var tvsFrank = {
  firstName: "TV's",
  id: tvsFrank_id,
  lastName: "Frank",
  matchCount: 35,
  rating: 1400,
  type_: /* Person */0
};

var cambot_id = Data_Id$Coronate.fromString("Cambot_______________");

var cambot = {
  firstName: "Cambot",
  id: cambot_id,
  lastName: "",
  matchCount: 25,
  rating: 1500,
  type_: /* Person */0
};

var jonah_id = Data_Id$Coronate.fromString("Jonah_Heston_________");

var jonah = {
  firstName: "Jonah",
  id: jonah_id,
  lastName: "Heston",
  matchCount: 50,
  rating: 2200,
  type_: /* Person */0
};

var kinga_id = Data_Id$Coronate.fromString("Kinga_Forrester______");

var kinga = {
  firstName: "Kinga",
  id: kinga_id,
  lastName: "Forrester",
  matchCount: 20,
  rating: 1900,
  type_: /* Person */0
};

var larry_id = Data_Id$Coronate.fromString("Larry_Erhardt________");

var larry = {
  firstName: "Larry",
  id: larry_id,
  lastName: "Erhardt",
  matchCount: 45,
  rating: 1300,
  type_: /* Person */0
};

var mike_id = Data_Id$Coronate.fromString("Mike_Nelson__________");

var mike = {
  firstName: "Mike",
  id: mike_id,
  lastName: "Nelson",
  matchCount: 60,
  rating: 2300,
  type_: /* Person */0
};

var observer_id = Data_Id$Coronate.fromString("Observer_Brain_Guy___");

var observer = {
  firstName: "Brain",
  id: observer_id,
  lastName: "Guy",
  matchCount: 55,
  rating: 1200,
  type_: /* Person */0
};

var pearl_id = Data_Id$Coronate.fromString("Pearl_Forrester______");

var pearl = {
  firstName: "Pearl",
  id: pearl_id,
  lastName: "Forrester",
  matchCount: 30,
  rating: 2000,
  type_: /* Person */0
};

var bobo_id = Data_Id$Coronate.fromString("Professor_Bobo_______");

var bobo = {
  firstName: "Bobo",
  id: bobo_id,
  lastName: "Professor",
  matchCount: 75,
  rating: 1000,
  type_: /* Person */0
};

var tvsSon_id = Data_Id$Coronate.fromString("TVs_Son_of_TVs_Frank_");

var tvsSon = {
  firstName: "TV's",
  id: tvsSon_id,
  lastName: "Max",
  matchCount: 65,
  rating: 1100,
  type_: /* Person */0
};

var deletedPlayer = Data_Id$Coronate.fromString("Deleted_Player_______");

var byeRoundTourney_id = Data_Id$Coronate.fromString("Bye_Round_Tourney____");

var byeRoundTourney_date = new Date("2019-06-12T23:49:47.103Z");

var byeRoundTourney_playerIds = Belt_Set.fromArray([
      joelRobinson_id,
      crowTRobot_id,
      tomServo_id,
      gypsy_id,
      cambot_id,
      newbieMcNewberson_id,
      grandyMcMaster_id
    ], Data_Id$Coronate.id);

var byeRoundTourney_scoreAdjustments = Belt_Map.make(Data_Id$Coronate.id);

var byeRoundTourney_byeQueue = [];

var byeRoundTourney_tieBreaks = [
  /* Median */0,
  /* Solkoff */1,
  /* Cumulative */2,
  /* CumulativeOfOpposition */3
];

var byeRoundTourney = {
  id: byeRoundTourney_id,
  name: "Bye Round Tourney",
  date: byeRoundTourney_date,
  playerIds: byeRoundTourney_playerIds,
  scoreAdjustments: byeRoundTourney_scoreAdjustments,
  byeQueue: byeRoundTourney_byeQueue,
  tieBreaks: byeRoundTourney_tieBreaks,
  roundList: Data_Rounds$Coronate.empty
};

var byeRoundTourney2_id = Data_Id$Coronate.fromString("Bye_Round_Tourney_2__");

var byeRoundTourney2_date = new Date("2019-06-12T23:49:47.103Z");

var byeRoundTourney2_playerIds = Belt_Set.fromArray([
      joelRobinson_id,
      crowTRobot_id,
      tomServo_id,
      gypsy_id,
      cambot_id,
      newbieMcNewberson_id,
      grandyMcMaster_id
    ], Data_Id$Coronate.id);

var byeRoundTourney2_scoreAdjustments = Belt_Map.make(Data_Id$Coronate.id);

var byeRoundTourney2_byeQueue = [];

var byeRoundTourney2_tieBreaks = [
  /* Median */0,
  /* Solkoff */1,
  /* Cumulative */2,
  /* CumulativeOfOpposition */3
];

var byeRoundTourney2_roundList = Data_Rounds$Coronate.fromArray([Data_Rounds$Coronate.Round.fromArray([
            {
              id: Data_Id$Coronate.fromString("xTXxZHB0sTt__xIAg45fm"),
              whiteId: grandyMcMaster_id,
              blackId: crowTRobot_id,
              whiteNewRating: 2592,
              blackNewRating: 1833,
              whiteOrigRating: 2600,
              blackOrigRating: 1700,
              result: /* BlackWon */1
            },
            {
              id: Data_Id$Coronate.fromString("zQcf9RWXK7iuU6ibPzrhU"),
              whiteId: joelRobinson_id,
              blackId: gypsy_id,
              whiteNewRating: 2400,
              blackNewRating: 1600,
              whiteOrigRating: 2400,
              blackOrigRating: 1600,
              result: /* WhiteWon */0
            },
            {
              id: Data_Id$Coronate.fromString("Vw_X0c7O4vshrYEO-oSzR"),
              whiteId: tomServo_id,
              blackId: cambot_id,
              whiteNewRating: 1738,
              blackNewRating: 1526,
              whiteOrigRating: 1800,
              blackOrigRating: 1500,
              result: /* BlackWon */1
            },
            {
              id: Data_Id$Coronate.fromString("iSDujOVkOTrcLv_KJmd7s"),
              whiteId: newbieMcNewberson_id,
              blackId: Data_Id$Coronate.dummy,
              whiteNewRating: 800,
              blackNewRating: 0,
              whiteOrigRating: 800,
              blackOrigRating: 0,
              result: /* WhiteWon */0
            }
          ])]);

var byeRoundTourney2 = {
  id: byeRoundTourney2_id,
  name: "Bye Round Tourney 2",
  date: byeRoundTourney2_date,
  playerIds: byeRoundTourney2_playerIds,
  scoreAdjustments: byeRoundTourney2_scoreAdjustments,
  byeQueue: byeRoundTourney2_byeQueue,
  tieBreaks: byeRoundTourney2_tieBreaks,
  roundList: byeRoundTourney2_roundList
};

var byeRoundTourney3_id = Data_Id$Coronate.fromString("Bye_Tourney_3________");

var byeRoundTourney3_date = new Date("2019-06-17T23:00:29.603Z");

var byeRoundTourney3_playerIds = Belt_Set.fromArray([
      kinga_id,
      newbieMcNewberson_id,
      jonah_id
    ], Data_Id$Coronate.id);

var byeRoundTourney3_scoreAdjustments = Belt_Map.make(Data_Id$Coronate.id);

var byeRoundTourney3_byeQueue = [];

var byeRoundTourney3_tieBreaks = [
  /* Median */0,
  /* Solkoff */1,
  /* Cumulative */2,
  /* CumulativeOfOpposition */3
];

var byeRoundTourney3_roundList = Data_Rounds$Coronate.fromArray([
      Data_Rounds$Coronate.Round.fromArray([
            {
              id: Data_Id$Coronate.fromString("KkFr4B7FDqiHRWmACgApf"),
              whiteId: kinga_id,
              blackId: jonah_id,
              whiteNewRating: 1894,
              blackNewRating: 2202,
              whiteOrigRating: 1900,
              blackOrigRating: 2200,
              result: /* BlackWon */1
            },
            {
              id: Data_Id$Coronate.fromString("R_BTsGSziwgyvFZM3yc5u"),
              whiteId: newbieMcNewberson_id,
              blackId: Data_Id$Coronate.dummy,
              whiteNewRating: 793,
              blackNewRating: 0,
              whiteOrigRating: 793,
              blackOrigRating: 0,
              result: /* WhiteWon */0
            }
          ]),
      Data_Rounds$Coronate.Round.fromArray([
            {
              id: Data_Id$Coronate.fromString("rcyCfpZU6olav5kdVac44"),
              whiteId: jonah_id,
              blackId: newbieMcNewberson_id,
              whiteNewRating: 2187,
              blackNewRating: 953,
              whiteOrigRating: 2202,
              blackOrigRating: 793,
              result: /* BlackWon */1
            },
            {
              id: Data_Id$Coronate.fromString("Nc0Om5fEuwSuzFls9wmME"),
              whiteId: kinga_id,
              blackId: Data_Id$Coronate.dummy,
              whiteNewRating: 1894,
              blackNewRating: 0,
              whiteOrigRating: 1894,
              blackOrigRating: 0,
              result: /* WhiteWon */0
            }
          ]),
      Data_Rounds$Coronate.Round.fromArray([
            {
              id: Data_Id$Coronate.fromString("uawjKwbiA38RP8pA--tlw"),
              whiteId: newbieMcNewberson_id,
              blackId: kinga_id,
              whiteNewRating: 952,
              blackNewRating: 1894,
              whiteOrigRating: 953,
              blackOrigRating: 1894,
              result: /* BlackWon */1
            },
            {
              id: Data_Id$Coronate.fromString("-kwIDxjPhWVRbqxtRZ26_"),
              whiteId: jonah_id,
              blackId: Data_Id$Coronate.dummy,
              whiteNewRating: 2187,
              blackNewRating: 0,
              whiteOrigRating: 2187,
              blackOrigRating: 0,
              result: /* WhiteWon */0
            }
          ])
    ]);

var byeRoundTourney3 = {
  id: byeRoundTourney3_id,
  name: "Bye Tourney 3",
  date: byeRoundTourney3_date,
  playerIds: byeRoundTourney3_playerIds,
  scoreAdjustments: byeRoundTourney3_scoreAdjustments,
  byeQueue: byeRoundTourney3_byeQueue,
  tieBreaks: byeRoundTourney3_tieBreaks,
  roundList: byeRoundTourney3_roundList
};

var simplePairing_id = Data_Id$Coronate.fromString("Simple_Pairing_______");

var simplePairing_date = new Date("2019-06-14T11:40:34.407Z");

var simplePairing_playerIds = Belt_Set.fromArray([
      newbieMcNewberson_id,
      grandyMcMaster_id,
      joelRobinson_id,
      drClaytonForrester_id,
      tvsFrank_id,
      crowTRobot_id,
      tomServo_id,
      gypsy_id
    ], Data_Id$Coronate.id);

var simplePairing_scoreAdjustments = Belt_Map.make(Data_Id$Coronate.id);

var simplePairing_byeQueue = [];

var simplePairing_tieBreaks = [
  /* Median */0,
  /* Solkoff */1,
  /* Cumulative */2,
  /* CumulativeOfOpposition */3
];

var simplePairing_roundList = Data_Rounds$Coronate.fromArray([
      Data_Rounds$Coronate.Round.fromArray([
            {
              id: Data_Id$Coronate.fromString("KdLva8hWqYHdaU9KnFTe2"),
              whiteId: crowTRobot_id,
              blackId: grandyMcMaster_id,
              whiteNewRating: 1699,
              blackNewRating: 2600,
              whiteOrigRating: 1700,
              blackOrigRating: 2600,
              result: /* BlackWon */1
            },
            {
              id: Data_Id$Coronate.fromString("WDPFsNF1yADs4qofFwCY0"),
              whiteId: gypsy_id,
              blackId: joelRobinson_id,
              whiteNewRating: 1650,
              blackNewRating: 2389,
              whiteOrigRating: 1600,
              blackOrigRating: 2400,
              result: /* WhiteWon */0
            },
            {
              id: Data_Id$Coronate.fromString("R5sXfTOJw5vrJ4IytAjSi"),
              whiteId: tvsFrank_id,
              blackId: drClaytonForrester_id,
              whiteNewRating: 1400,
              blackNewRating: 2100,
              whiteOrigRating: 1400,
              blackOrigRating: 2100,
              result: /* BlackWon */1
            },
            {
              id: Data_Id$Coronate.fromString("2YOsn_JJFnaUMhRBAc9KY"),
              whiteId: newbieMcNewberson_id,
              blackId: tomServo_id,
              whiteNewRating: 1597,
              blackNewRating: 1728,
              whiteOrigRating: 800,
              blackOrigRating: 1800,
              result: /* WhiteWon */0
            }
          ]),
      Data_Rounds$Coronate.Round.empty
    ]);

var simplePairing = {
  id: simplePairing_id,
  name: "Simple Pairing",
  date: simplePairing_date,
  playerIds: simplePairing_playerIds,
  scoreAdjustments: simplePairing_scoreAdjustments,
  byeQueue: simplePairing_byeQueue,
  tieBreaks: simplePairing_tieBreaks,
  roundList: simplePairing_roundList
};

var deletedPlayerTourney_id = Data_Id$Coronate.fromString("Deleted_Player_Torney");

var deletedPlayerTourney_date = new Date("2020-12-24T11:40:34.407Z");

var deletedPlayerTourney_playerIds = Belt_Set.fromArray([
      newbieMcNewberson_id,
      grandyMcMaster_id,
      joelRobinson_id,
      drClaytonForrester_id,
      tvsFrank_id,
      crowTRobot_id,
      tomServo_id,
      deletedPlayer
    ], Data_Id$Coronate.id);

var deletedPlayerTourney_scoreAdjustments = Belt_Map.make(Data_Id$Coronate.id);

var deletedPlayerTourney_byeQueue = [deletedPlayer];

var deletedPlayerTourney_tieBreaks = [
  /* Median */0,
  /* Solkoff */1,
  /* Cumulative */2,
  /* CumulativeOfOpposition */3
];

var deletedPlayerTourney_roundList = Data_Rounds$Coronate.fromArray([
      Data_Rounds$Coronate.Round.fromArray([
            {
              id: Data_Id$Coronate.fromString("KdLva8hWqYHdaU9KnFTe2"),
              whiteId: crowTRobot_id,
              blackId: grandyMcMaster_id,
              whiteNewRating: 1699,
              blackNewRating: 2600,
              whiteOrigRating: 1700,
              blackOrigRating: 2600,
              result: /* BlackWon */1
            },
            {
              id: Data_Id$Coronate.fromString("WDPFsNF1yADs4qofFwCY0"),
              whiteId: deletedPlayer,
              blackId: joelRobinson_id,
              whiteNewRating: 1650,
              blackNewRating: 2389,
              whiteOrigRating: 1600,
              blackOrigRating: 2400,
              result: /* WhiteWon */0
            },
            {
              id: Data_Id$Coronate.fromString("R5sXfTOJw5vrJ4IytAjSi"),
              whiteId: tvsFrank_id,
              blackId: drClaytonForrester_id,
              whiteNewRating: 1400,
              blackNewRating: 2100,
              whiteOrigRating: 1400,
              blackOrigRating: 2100,
              result: /* BlackWon */1
            },
            {
              id: Data_Id$Coronate.fromString("2YOsn_JJFnaUMhRBAc9KY"),
              whiteId: newbieMcNewberson_id,
              blackId: tomServo_id,
              whiteNewRating: 1597,
              blackNewRating: 1728,
              whiteOrigRating: 800,
              blackOrigRating: 1800,
              result: /* WhiteWon */0
            }
          ]),
      Data_Rounds$Coronate.Round.empty
    ]);

var deletedPlayerTourney = {
  id: deletedPlayerTourney_id,
  name: "Deleted Player",
  date: deletedPlayerTourney_date,
  playerIds: deletedPlayerTourney_playerIds,
  scoreAdjustments: deletedPlayerTourney_scoreAdjustments,
  byeQueue: deletedPlayerTourney_byeQueue,
  tieBreaks: deletedPlayerTourney_tieBreaks,
  roundList: deletedPlayerTourney_roundList
};

var pairingWithDraws_id = Data_Id$Coronate.fromString("Pairing_With_Draws___");

var pairingWithDraws_date = new Date("2019-06-14T14:18:06.686Z");

var pairingWithDraws_playerIds = Belt_Set.fromArray([
      newbieMcNewberson_id,
      grandyMcMaster_id,
      joelRobinson_id,
      drClaytonForrester_id,
      tvsFrank_id,
      crowTRobot_id,
      tomServo_id,
      gypsy_id
    ], Data_Id$Coronate.id);

var pairingWithDraws_scoreAdjustments = Belt_Map.make(Data_Id$Coronate.id);

var pairingWithDraws_byeQueue = [];

var pairingWithDraws_tieBreaks = [
  /* Median */0,
  /* Solkoff */1,
  /* Cumulative */2,
  /* CumulativeOfOpposition */3
];

var pairingWithDraws_roundList = Data_Rounds$Coronate.fromArray([
      Data_Rounds$Coronate.Round.fromArray([
            {
              id: Data_Id$Coronate.fromString("ryWXwvFGwBKQqGBbMYeps"),
              whiteId: crowTRobot_id,
              blackId: grandyMcMaster_id,
              whiteNewRating: 1699,
              blackNewRating: 2600,
              whiteOrigRating: 1700,
              blackOrigRating: 2600,
              result: /* BlackWon */1
            },
            {
              id: Data_Id$Coronate.fromString("2KKVbi0AfNxfJAJobTgP5"),
              whiteId: gypsy_id,
              blackId: joelRobinson_id,
              whiteNewRating: 1650,
              blackNewRating: 2389,
              whiteOrigRating: 1600,
              blackOrigRating: 2400,
              result: /* WhiteWon */0
            },
            {
              id: Data_Id$Coronate.fromString("zdlBHBAqgV2qabn2oBa2a"),
              whiteId: tvsFrank_id,
              blackId: drClaytonForrester_id,
              whiteNewRating: 1400,
              blackNewRating: 2100,
              whiteOrigRating: 1400,
              blackOrigRating: 2100,
              result: /* BlackWon */1
            },
            {
              id: Data_Id$Coronate.fromString("8fGxU3tLpd8GibuSQr9-Y"),
              whiteId: newbieMcNewberson_id,
              blackId: tomServo_id,
              whiteNewRating: 1197,
              blackNewRating: 1764,
              whiteOrigRating: 800,
              blackOrigRating: 1800,
              result: /* Draw */2
            }
          ]),
      Data_Rounds$Coronate.Round.empty
    ]);

var pairingWithDraws = {
  id: pairingWithDraws_id,
  name: "Pairing with draws",
  date: pairingWithDraws_date,
  playerIds: pairingWithDraws_playerIds,
  scoreAdjustments: pairingWithDraws_scoreAdjustments,
  byeQueue: pairingWithDraws_byeQueue,
  tieBreaks: pairingWithDraws_tieBreaks,
  roundList: pairingWithDraws_roundList
};

var scoreTest_id = Data_Id$Coronate.fromString("WY_AzAeDDZeHMbhgUVuum");

var scoreTest_date = new Date("2020-06-28T12:42:46.347Z");

var scoreTest_playerIds = Belt_Set.fromArray([
      joelRobinson_id,
      mike_id,
      bobo_id,
      tomServo_id,
      tvsSon_id,
      tvsFrank_id,
      cambot_id,
      drClaytonForrester_id,
      crowTRobot_id,
      pearl_id,
      observer_id,
      kinga_id,
      jonah_id
    ], Data_Id$Coronate.id);

var scoreTest_scoreAdjustments = Belt_Map.make(Data_Id$Coronate.id);

var scoreTest_byeQueue = [];

var scoreTest_tieBreaks = [
  /* Median */0,
  /* Solkoff */1,
  /* Cumulative */2,
  /* CumulativeOfOpposition */3
];

var scoreTest_roundList = Data_Rounds$Coronate.fromArray([
      Data_Rounds$Coronate.Round.fromArray([
            {
              id: Data_Id$Coronate.fromString("IPRs48UZ3ubxbyzs9-Aj8"),
              whiteId: joelRobinson_id,
              blackId: tomServo_id,
              whiteNewRating: 2400,
              blackNewRating: 1798,
              whiteOrigRating: 2400,
              blackOrigRating: 1800,
              result: /* WhiteWon */0
            },
            {
              id: Data_Id$Coronate.fromString("tcrk4_Eze6YB1jfgFxMg2"),
              whiteId: crowTRobot_id,
              blackId: mike_id,
              whiteNewRating: 1695,
              blackNewRating: 2300,
              whiteOrigRating: 1700,
              blackOrigRating: 2300,
              result: /* BlackWon */1
            },
            {
              id: Data_Id$Coronate.fromString("g30WVQazEVlyFZoB_iKNP"),
              whiteId: cambot_id,
              blackId: jonah_id,
              whiteNewRating: 1531,
              blackNewRating: 2184,
              whiteOrigRating: 1500,
              blackOrigRating: 2200,
              result: /* WhiteWon */0
            },
            {
              id: Data_Id$Coronate.fromString("z6z2X8Al9iNDmx2rc6iSd"),
              whiteId: drClaytonForrester_id,
              blackId: tvsFrank_id,
              whiteNewRating: 2080,
              blackNewRating: 1422,
              whiteOrigRating: 2100,
              blackOrigRating: 1400,
              result: /* BlackWon */1
            },
            {
              id: Data_Id$Coronate.fromString("_wRcKBpFnPspJeiglbv42"),
              whiteId: observer_id,
              blackId: pearl_id,
              whiteNewRating: 1214,
              blackNewRating: 1974,
              whiteOrigRating: 1200,
              blackOrigRating: 2000,
              result: /* WhiteWon */0
            },
            {
              id: Data_Id$Coronate.fromString("g35wmUfw3--3dZvF4Dxai"),
              whiteId: kinga_id,
              blackId: tvsSon_id,
              whiteNewRating: 1860,
              blackNewRating: 1112,
              whiteOrigRating: 1900,
              blackOrigRating: 1100,
              result: /* BlackWon */1
            },
            {
              id: Data_Id$Coronate.fromString("ZFwPgPSrdR0dsiROQpZmq"),
              whiteId: bobo_id,
              blackId: Data_Id$Coronate.dummy,
              whiteNewRating: 1000,
              blackNewRating: 0,
              whiteOrigRating: 1000,
              blackOrigRating: 0,
              result: /* WhiteWon */0
            }
          ]),
      Data_Rounds$Coronate.Round.fromArray([
            {
              id: Data_Id$Coronate.fromString("gvr8PMNdqVwvMHpQZhxKW"),
              whiteId: tvsFrank_id,
              blackId: joelRobinson_id,
              whiteNewRating: 1444,
              blackNewRating: 2389,
              whiteOrigRating: 1422,
              blackOrigRating: 2400,
              result: /* WhiteWon */0
            },
            {
              id: Data_Id$Coronate.fromString("n02yWL6MNgkS19IREj_Df"),
              whiteId: mike_id,
              blackId: observer_id,
              whiteNewRating: 2287,
              blackNewRating: 1228,
              whiteOrigRating: 2300,
              blackOrigRating: 1214,
              result: /* BlackWon */1
            },
            {
              id: Data_Id$Coronate.fromString("Luic7R_pGjZmSYxN1uNmc"),
              whiteId: tvsSon_id,
              blackId: cambot_id,
              whiteNewRating: 1123,
              blackNewRating: 1503,
              whiteOrigRating: 1112,
              blackOrigRating: 1531,
              result: /* WhiteWon */0
            },
            {
              id: Data_Id$Coronate.fromString("Ew_xdm0tKHE1423CYoApT"),
              whiteId: pearl_id,
              blackId: bobo_id,
              whiteNewRating: 1949,
              blackNewRating: 1010,
              whiteOrigRating: 1974,
              blackOrigRating: 1000,
              result: /* BlackWon */1
            },
            {
              id: Data_Id$Coronate.fromString("steThF-oNT-fBZe4AUHjc"),
              whiteId: jonah_id,
              blackId: kinga_id,
              whiteNewRating: 2186,
              blackNewRating: 1855,
              whiteOrigRating: 2184,
              blackOrigRating: 1860,
              result: /* WhiteWon */0
            },
            {
              id: Data_Id$Coronate.fromString("3QPqo0tMvKVzgaQOFjezL"),
              whiteId: tomServo_id,
              blackId: drClaytonForrester_id,
              whiteNewRating: 1786,
              blackNewRating: 2083,
              whiteOrigRating: 1798,
              blackOrigRating: 2080,
              result: /* BlackWon */1
            },
            {
              id: Data_Id$Coronate.fromString("BsbnpljVf9OagbfT8sUhJ"),
              whiteId: crowTRobot_id,
              blackId: Data_Id$Coronate.dummy,
              whiteNewRating: 1695,
              blackNewRating: 0,
              whiteOrigRating: 1695,
              blackOrigRating: 0,
              result: /* WhiteWon */0
            }
          ]),
      Data_Rounds$Coronate.Round.fromArray([
            {
              id: Data_Id$Coronate.fromString("lqRRkcPXADxNcZoYNUs4O"),
              whiteId: bobo_id,
              blackId: tvsFrank_id,
              whiteNewRating: 1019,
              blackNewRating: 1425,
              whiteOrigRating: 1010,
              blackOrigRating: 1444,
              result: /* WhiteWon */0
            },
            {
              id: Data_Id$Coronate.fromString("tFw25nJzFa1DNC3kEBlvL"),
              whiteId: observer_id,
              blackId: tvsSon_id,
              whiteNewRating: 1219,
              blackNewRating: 1130,
              whiteOrigRating: 1228,
              blackOrigRating: 1123,
              result: /* BlackWon */1
            },
            {
              id: Data_Id$Coronate.fromString("4s3IVU-DxR2itlDt6Pbry"),
              whiteId: drClaytonForrester_id,
              blackId: jonah_id,
              whiteNewRating: 2095,
              blackNewRating: 2176,
              whiteOrigRating: 2083,
              blackOrigRating: 2186,
              result: /* WhiteWon */0
            },
            {
              id: Data_Id$Coronate.fromString("-J00pFQGNydkCVJ9IY57T"),
              whiteId: cambot_id,
              blackId: mike_id,
              whiteNewRating: 1503,
              blackNewRating: 2287,
              whiteOrigRating: 1503,
              blackOrigRating: 2287,
              result: /* BlackWon */1
            },
            {
              id: Data_Id$Coronate.fromString("aN_Nzb1GFvoIT1Dlu3bn9"),
              whiteId: joelRobinson_id,
              blackId: pearl_id,
              whiteNewRating: 2390,
              blackNewRating: 1947,
              whiteOrigRating: 2389,
              blackOrigRating: 1949,
              result: /* WhiteWon */0
            },
            {
              id: Data_Id$Coronate.fromString("taKyrpJg9XpACuWq16bum"),
              whiteId: kinga_id,
              blackId: crowTRobot_id,
              whiteNewRating: 1829,
              blackNewRating: 1790,
              whiteOrigRating: 1855,
              blackOrigRating: 1695,
              result: /* BlackWon */1
            },
            {
              id: Data_Id$Coronate.fromString("Rh_UVxVKowtjSpeH-4Xwc"),
              whiteId: tomServo_id,
              blackId: Data_Id$Coronate.dummy,
              whiteNewRating: 1786,
              blackNewRating: 0,
              whiteOrigRating: 1786,
              blackOrigRating: 0,
              result: /* WhiteWon */0
            }
          ]),
      Data_Rounds$Coronate.Round.fromArray([])
    ]);

var scoreTest = {
  id: scoreTest_id,
  name: "Score testing",
  date: scoreTest_date,
  playerIds: scoreTest_playerIds,
  scoreAdjustments: scoreTest_scoreAdjustments,
  byeQueue: scoreTest_byeQueue,
  tieBreaks: scoreTest_tieBreaks,
  roundList: scoreTest_roundList
};

function decode(json) {
  var dict = Belt_Option.getExn(Js_json.decodeObject(json.players));
  var dict$1 = Belt_Option.getExn(Js_json.decodeObject(json.tournaments));
  return {
          config: Data_Config$Coronate.decode(json.config),
          players: Belt_Map.map(Data_Id$Coronate.$$Map.fromStringArray(Js_dict.entries(dict)), Data_Player$Coronate.decode),
          tournaments: Belt_Map.map(Data_Id$Coronate.$$Map.fromStringArray(Js_dict.entries(dict$1)), Data_Tournament$Coronate.decode)
        };
}

var fixturePairingApril22 = FixturePairingAprilJson;

var fixturePairingApril22$1 = decode(fixturePairingApril22);

function mapMerger(k, a, b) {
  if (a !== undefined) {
    if (b !== undefined) {
      return Pervasives.failwith("Duplicate " + Data_Id$Coronate.toString(k));
    } else {
      return a;
    }
  } else if (b !== undefined) {
    return b;
  } else {
    return ;
  }
}

var config_avoidPairs = Belt_Set.fromArray(Belt_Array.keepMap([
          [
            tvsFrank_id,
            tvsSon_id
          ],
          [
            pearl_id,
            drClaytonForrester_id
          ],
          [
            kinga_id,
            drClaytonForrester_id
          ],
          [
            kinga_id,
            pearl_id
          ]
        ], (function (param) {
            return Data_Id$Coronate.Pair.make(param[0], param[1]);
          })), Data_Id$Coronate.Pair.id);

var config_lastBackup = new Date("1970-01-01T00:00:00.000Z");

var config = {
  avoidPairs: config_avoidPairs,
  byeValue: /* Full */0,
  lastBackup: config_lastBackup
};

var players = Belt_Map.merge(Belt_Map.fromArray([
          [
            cambot_id,
            cambot
          ],
          [
            crowTRobot_id,
            crowTRobot
          ],
          [
            drClaytonForrester_id,
            drClaytonForrester
          ],
          [
            grandyMcMaster_id,
            grandyMcMaster
          ],
          [
            gypsy_id,
            gypsy
          ],
          [
            joelRobinson_id,
            joelRobinson
          ],
          [
            jonah_id,
            jonah
          ],
          [
            kinga_id,
            kinga
          ],
          [
            larry_id,
            larry
          ],
          [
            mike_id,
            mike
          ],
          [
            newbieMcNewberson_id,
            newbieMcNewberson
          ],
          [
            observer_id,
            observer
          ],
          [
            pearl_id,
            pearl
          ],
          [
            bobo_id,
            bobo
          ],
          [
            tvsFrank_id,
            tvsFrank
          ],
          [
            tvsSon_id,
            tvsSon
          ],
          [
            tomServo_id,
            tomServo
          ]
        ], Data_Id$Coronate.id), fixturePairingApril22$1.players, mapMerger);

var tournaments = Belt_Map.merge(Belt_Map.fromArray([
          [
            byeRoundTourney_id,
            byeRoundTourney
          ],
          [
            byeRoundTourney2_id,
            byeRoundTourney2
          ],
          [
            byeRoundTourney3_id,
            byeRoundTourney3
          ],
          [
            simplePairing_id,
            simplePairing
          ],
          [
            pairingWithDraws_id,
            pairingWithDraws
          ],
          [
            scoreTest_id,
            scoreTest
          ],
          [
            deletedPlayerTourney_id,
            deletedPlayerTourney
          ]
        ], Data_Id$Coronate.id), fixturePairingApril22$1.tournaments, mapMerger);

export {
  crowTRobot ,
  drClaytonForrester ,
  grandyMcMaster ,
  gypsy ,
  joelRobinson ,
  newbieMcNewberson ,
  tomServo ,
  tvsFrank ,
  deletedPlayer ,
  byeRoundTourney ,
  byeRoundTourney2 ,
  byeRoundTourney3 ,
  simplePairing ,
  pairingWithDraws ,
  scoreTest ,
  deletedPlayerTourney ,
  config ,
  players ,
  tournaments ,
  
}
/* crowTRobot Not a pure module */
