// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Db$Coronate from "./Db.bs.js";
import * as ReactFeather from "react-feather";
import * as Hooks$Coronate from "./Hooks.bs.js";
import * as Utils$Coronate from "./Utils.bs.js";
import * as Router$Coronate from "./Router.bs.js";
import * as Window$Coronate from "./Window.bs.js";
import * as Data_Id$Coronate from "./Data/Data_Id.bs.js";
import * as Externals$Coronate from "./Externals/Externals.bs.js";
import * as Data_Player$Coronate from "./Data/Data_Player.bs.js";
import * as Data_Ratings$Coronate from "./Data/Data_Ratings.bs.js";
import VisuallyHidden from "@reach/visually-hidden";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";

var sortName = {
  TAG: /* GetString */0,
  _0: (function (x) {
      return x.firstName;
    })
};

var sortRating = {
  TAG: /* GetInt */1,
  _0: (function (x) {
      return x.rating;
    })
};

var sortMatchCount = {
  TAG: /* GetInt */1,
  _0: (function (x) {
      return x.matchCount;
    })
};

var validators_matchCount = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      var rating = Belt_Int.fromString(param.matchCount);
      if (rating !== undefined) {
        return {
                TAG: /* Ok */0,
                _0: rating
              };
      } else {
        return {
                TAG: /* Error */1,
                _0: "Match count must be a number"
              };
      }
    })
};

var validators_rating = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      var rating = Belt_Int.fromString(param.rating);
      if (rating !== undefined) {
        return {
                TAG: /* Ok */0,
                _0: rating
              };
      } else {
        return {
                TAG: /* Error */1,
                _0: "Rating must be a number"
              };
      }
    })
};

var validators_lastName = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      var lastName = param.lastName;
      if (lastName === "") {
        return {
                TAG: /* Error */1,
                _0: "Last name is required"
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: lastName
              };
      }
    })
};

var validators_firstName = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      var firstName = param.firstName;
      if (firstName === "") {
        return {
                TAG: /* Error */1,
                _0: "First name is required"
              };
      } else {
        return {
                TAG: /* Ok */0,
                _0: firstName
              };
      }
    })
};

var validators = {
  matchCount: validators_matchCount,
  rating: validators_rating,
  lastName: validators_lastName,
  firstName: validators_firstName
};

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: {
            matchCount: /* Pristine */0,
            rating: /* Pristine */0,
            lastName: /* Pristine */0,
            firstName: /* Pristine */0
          },
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.matchCount;
  var match_0 = match ? match._0 : Curry._1(validators.matchCount.validate, input);
  var match$1 = fieldsStatuses.rating;
  var match_0$1 = match$1 ? match$1._0 : Curry._1(validators.rating.validate, input);
  var match$2 = fieldsStatuses.lastName;
  var match_0$2 = match$2 ? match$2._0 : Curry._1(validators.lastName.validate, input);
  var match$3 = fieldsStatuses.firstName;
  var match_0$3 = match$3 ? match$3._0 : Curry._1(validators.firstName.validate, input);
  var matchCountResult = match_0;
  var matchCountResult$1;
  if (matchCountResult.TAG === /* Ok */0) {
    var ratingResult = match_0$1;
    if (ratingResult.TAG === /* Ok */0) {
      var lastNameResult = match_0$2;
      if (lastNameResult.TAG === /* Ok */0) {
        var firstNameResult = match_0$3;
        if (firstNameResult.TAG === /* Ok */0) {
          return {
                  TAG: /* Valid */0,
                  output: {
                    firstName: firstNameResult._0,
                    lastName: lastNameResult._0,
                    rating: ratingResult._0,
                    matchCount: matchCountResult._0
                  },
                  fieldsStatuses: {
                    matchCount: /* Dirty */{
                      _0: matchCountResult,
                      _1: /* Shown */0
                    },
                    rating: /* Dirty */{
                      _0: ratingResult,
                      _1: /* Shown */0
                    },
                    lastName: /* Dirty */{
                      _0: lastNameResult,
                      _1: /* Shown */0
                    },
                    firstName: /* Dirty */{
                      _0: firstNameResult,
                      _1: /* Shown */0
                    }
                  },
                  collectionsStatuses: undefined
                };
        }
        matchCountResult$1 = matchCountResult;
      } else {
        matchCountResult$1 = matchCountResult;
      }
    } else {
      matchCountResult$1 = matchCountResult;
    }
  } else {
    matchCountResult$1 = matchCountResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            matchCount: /* Dirty */{
              _0: matchCountResult$1,
              _1: /* Shown */0
            },
            rating: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            lastName: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            firstName: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurMatchCountField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.matchCount, validators_matchCount, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  matchCount: status,
                                  rating: init.rating,
                                  lastName: init.lastName,
                                  firstName: init.firstName
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurRatingField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.rating, validators_rating, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  matchCount: init.matchCount,
                                  rating: status,
                                  lastName: init.lastName,
                                  firstName: init.firstName
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurLastNameField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.lastName, validators_lastName, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  matchCount: init.matchCount,
                                  rating: init.rating,
                                  lastName: status,
                                  firstName: init.firstName
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurFirstNameField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.firstName, validators_firstName, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  matchCount: init.matchCount,
                                  rating: init.rating,
                                  lastName: init.lastName,
                                  firstName: status
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */4 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              return Curry._2(onSubmit, output, {
                                          notifyOnSuccess: (function (input) {
                                              return Curry._1(dispatch, {
                                                          TAG: /* SetSubmittedStatus */4,
                                                          _0: input
                                                        });
                                            }),
                                          notifyOnFailure: (function (error) {
                                              return Curry._1(dispatch, {
                                                          TAG: /* SetSubmissionFailedStatus */5,
                                                          _0: error
                                                        });
                                            }),
                                          reset: (function (param) {
                                              return Curry._1(dispatch, /* Reset */7);
                                            }),
                                          dismissSubmissionResult: (function (param) {
                                              return Curry._1(dispatch, /* DismissSubmissionResult */6);
                                            })
                                        });
                            })
                        };
                  break;
              case /* DismissSubmissionError */5 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */6 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */7 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateMatchCountField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.matchCount, state.submissionStatus, validators_matchCount, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            matchCount: status,
                                            rating: init.rating,
                                            lastName: init.lastName,
                                            firstName: init.firstName
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateRatingField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.rating, state.submissionStatus, validators_rating, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            matchCount: init.matchCount,
                                            rating: status,
                                            lastName: init.lastName,
                                            firstName: init.firstName
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateLastNameField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.lastName, state.submissionStatus, validators_lastName, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            matchCount: init.matchCount,
                                            rating: init.rating,
                                            lastName: status,
                                            firstName: init.firstName
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateFirstNameField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$3, state.fieldsStatuses.firstName, state.submissionStatus, validators_firstName, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            matchCount: init.matchCount,
                                            rating: init.rating,
                                            lastName: init.lastName,
                                            firstName: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */4 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: {
                                matchCount: /* Pristine */0,
                                rating: /* Pristine */0,
                                lastName: /* Pristine */0,
                                firstName: /* Pristine */0
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: {
                                matchCount: /* Pristine */0,
                                rating: /* Pristine */0,
                                lastName: /* Pristine */0,
                                firstName: /* Pristine */0
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */5 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */6 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Caml_option.some(Curry._1(map, Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateMatchCount: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateMatchCountField */0,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          updateRating: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateRatingField */1,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          updateLastName: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateLastNameField */2,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          updateFirstName: (function (nextInputFn, nextValue) {
              return Curry._1(dispatch, {
                          TAG: /* UpdateFirstNameField */3,
                          _0: (function (__x) {
                              return Curry._2(nextInputFn, __x, nextValue);
                            })
                        });
            }),
          blurMatchCount: (function (param) {
              return Curry._1(dispatch, /* BlurMatchCountField */0);
            }),
          blurRating: (function (param) {
              return Curry._1(dispatch, /* BlurRatingField */1);
            }),
          blurLastName: (function (param) {
              return Curry._1(dispatch, /* BlurLastNameField */2);
            }),
          blurFirstName: (function (param) {
              return Curry._1(dispatch, /* BlurFirstNameField */3);
            }),
          matchCountResult: Formality.exposeFieldResult(state.fieldsStatuses.matchCount),
          ratingResult: Formality.exposeFieldResult(state.fieldsStatuses.rating),
          lastNameResult: Formality.exposeFieldResult(state.fieldsStatuses.lastName),
          firstNameResult: Formality.exposeFieldResult(state.fieldsStatuses.firstName),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.matchCount || match.rating || match.lastName || match.firstName) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              return Curry._1(dispatch, /* Submit */4);
            }),
          dismissSubmissionError: (function (param) {
              return Curry._1(dispatch, /* DismissSubmissionError */5);
            }),
          dismissSubmissionResult: (function (param) {
              return Curry._1(dispatch, /* DismissSubmissionResult */6);
            }),
          mapSubmissionError: (function (map) {
              return Curry._1(dispatch, {
                          TAG: /* MapSubmissionError */6,
                          _0: map
                        });
            }),
          reset: (function (param) {
              return Curry._1(dispatch, /* Reset */7);
            })
        };
}

function errorNotification(x) {
  if (x !== undefined && x.TAG !== /* Ok */0) {
    return React.createElement(Utils$Coronate.$$Notification.make, {
                children: x._0,
                kind: /* Error */2
              });
  } else {
    return null;
  }
}

function PagePlayers$NewPlayerForm(Props) {
  var dispatch = Props.dispatch;
  var addPlayerCallback = Props.addPlayerCallback;
  var form = useForm({
        firstName: "",
        lastName: "",
        rating: "1200",
        matchCount: "0"
      }, (function (param, cb) {
          var id = Data_Id$Coronate.random(undefined);
          Curry._1(dispatch, {
                TAG: /* Set */1,
                _0: id,
                _1: {
                  firstName: param.firstName,
                  id: id,
                  lastName: param.lastName,
                  matchCount: param.matchCount,
                  rating: param.rating,
                  type_: /* Person */0
                }
              });
          if (addPlayerCallback !== undefined) {
            Curry._1(addPlayerCallback, id);
          }
          Curry._1(cb.notifyOnSuccess, undefined);
          return Curry._1(cb.reset, undefined);
        }));
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  return Curry._1(form.submit, undefined);
                })
            }, React.createElement("fieldset", undefined, React.createElement("legend", undefined, "Register a new player"), React.createElement("p", undefined, React.createElement("label", {
                          htmlFor: "firstName"
                        }, "First name"), React.createElement("input", {
                          name: "firstName",
                          required: true,
                          type: "text",
                          value: form.input.firstName,
                          onBlur: (function (param) {
                              return Curry._1(form.blurFirstName, undefined);
                            }),
                          onChange: (function ($$event) {
                              return Curry._2(form.updateFirstName, (function (input, firstName) {
                                            return {
                                                    firstName: firstName,
                                                    lastName: input.lastName,
                                                    rating: input.rating,
                                                    matchCount: input.matchCount
                                                  };
                                          }), $$event.target.value);
                            })
                        })), errorNotification(form.firstNameResult), React.createElement("p", undefined, React.createElement("label", {
                          htmlFor: "lastName"
                        }, "Last name"), React.createElement("input", {
                          name: "lastName",
                          required: true,
                          type: "text",
                          value: form.input.lastName,
                          onBlur: (function (param) {
                              return Curry._1(form.blurLastName, undefined);
                            }),
                          onChange: (function ($$event) {
                              return Curry._2(form.updateLastName, (function (input, lastName) {
                                            return {
                                                    firstName: input.firstName,
                                                    lastName: lastName,
                                                    rating: input.rating,
                                                    matchCount: input.matchCount
                                                  };
                                          }), $$event.target.value);
                            })
                        })), errorNotification(form.lastNameResult), React.createElement("p", undefined, React.createElement("label", {
                          htmlFor: "form-newplayer-rating"
                        }, "Rating"), React.createElement("input", {
                          id: "form-newplayer-rating",
                          name: "rating",
                          required: true,
                          type: "number",
                          value: form.input.rating,
                          onBlur: (function (param) {
                              return Curry._1(form.blurRating, undefined);
                            }),
                          onChange: (function ($$event) {
                              return Curry._2(form.updateLastName, (function (input, rating) {
                                            return {
                                                    firstName: input.firstName,
                                                    lastName: input.lastName,
                                                    rating: rating,
                                                    matchCount: input.matchCount
                                                  };
                                          }), $$event.target.value);
                            })
                        })), errorNotification(form.ratingResult), React.createElement("p", undefined, React.createElement("button", {
                          disabled: form.submitting || !Curry._1(form.valid, undefined)
                        }, "Add"))));
}

var NewPlayerForm = {
  make: PagePlayers$NewPlayerForm
};

function PagePlayers$PlayerList(Props) {
  var sorted = Props.sorted;
  var sortDispatch = Props.sortDispatch;
  var players = Props.players;
  var playersDispatch = Props.playersDispatch;
  var configDispatch = Props.configDispatch;
  var windowDispatchOpt = Props.windowDispatch;
  var windowDispatch = windowDispatchOpt !== undefined ? windowDispatchOpt : (function (param) {
        
      });
  var dialog = Hooks$Coronate.useBool(false);
  React.useEffect((function () {
          Curry._1(windowDispatch, {
                TAG: /* SetTitle */2,
                _0: "Players"
              });
          return (function (param) {
                    return Curry._1(windowDispatch, {
                                TAG: /* SetTitle */2,
                                _0: ""
                              });
                  });
        }), [windowDispatch]);
  return React.createElement("div", {
              className: "content-area"
            }, React.createElement("div", {
                  className: "toolbar toolbar__left"
                }, React.createElement("button", {
                      onClick: (function (param) {
                          return Curry._1(dialog.setTrue, undefined);
                        })
                    }, React.createElement(ReactFeather.UserPlus, {}), " Add a new player")), React.createElement("table", {
                  style: {
                    margin: "auto"
                  }
                }, React.createElement("caption", undefined, "Player roster"), React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", undefined, React.createElement(Hooks$Coronate.SortButton.make, {
                                  children: "Name",
                                  sortColumn: sortName,
                                  data: sorted,
                                  dispatch: sortDispatch
                                })), React.createElement("th", undefined, React.createElement(Hooks$Coronate.SortButton.make, {
                                  children: "Rating",
                                  sortColumn: sortRating,
                                  data: sorted,
                                  dispatch: sortDispatch
                                })), React.createElement("th", undefined, React.createElement(Hooks$Coronate.SortButton.make, {
                                  children: "Matches",
                                  sortColumn: sortMatchCount,
                                  data: sorted,
                                  dispatch: sortDispatch
                                })), React.createElement("th", undefined, React.createElement(VisuallyHidden, {
                                  children: "Controls"
                                })))), React.createElement("tbody", {
                      className: "content"
                    }, Belt_Array.map(sorted.table, (function (p) {
                            return React.createElement("tr", {
                                        key: Data_Id$Coronate.toString(p.id)
                                      }, React.createElement("td", {
                                            className: "table__player"
                                          }, React.createElement(Router$Coronate.Link.make, {
                                                children: Data_Player$Coronate.fullName(p),
                                                to_: {
                                                  TAG: /* Player */1,
                                                  _0: p.id
                                                }
                                              })), React.createElement("td", {
                                            className: "table__number"
                                          }, String(p.rating)), React.createElement("td", {
                                            className: "table__number"
                                          }, String(p.matchCount)), React.createElement("td", undefined, React.createElement("button", {
                                                className: "danger button-ghost",
                                                onClick: (function ($$event) {
                                                    var id = p.id;
                                                    $$event.preventDefault();
                                                    var playerOpt = Belt_Map.get(players, id);
                                                    if (playerOpt === undefined) {
                                                      return ;
                                                    }
                                                    var message = "Are you sure you want to delete " + Data_Player$Coronate.fullName(playerOpt) + "?";
                                                    if (window.confirm(message)) {
                                                      Curry._1(playersDispatch, {
                                                            TAG: /* Del */0,
                                                            _0: id
                                                          });
                                                      return Curry._1(configDispatch, {
                                                                  TAG: /* DelAvoidSingle */2,
                                                                  _0: id
                                                                });
                                                    }
                                                    
                                                  })
                                              }, React.createElement(ReactFeather.Trash2, {}), React.createElement(VisuallyHidden, {
                                                    children: "Delete " + Data_Player$Coronate.fullName(p)
                                                  }))));
                          })))), React.createElement(Externals$Coronate.Dialog.make, {
                  isOpen: dialog.state,
                  onDismiss: (function (param) {
                      return Curry._1(dialog.setFalse, undefined);
                    }),
                  ariaLabel: "New player form",
                  children: null,
                  className: ""
                }, React.createElement("button", {
                      className: "button-micro",
                      onClick: (function (param) {
                          return Curry._1(dialog.setFalse, undefined);
                        })
                    }, "Close"), React.createElement(PagePlayers$NewPlayerForm, {
                      dispatch: playersDispatch
                    })));
}

function PagePlayers$Profile(Props) {
  var player = Props.player;
  var players = Props.players;
  var playersDispatch = Props.playersDispatch;
  var config = Props.config;
  var configDispatch = Props.configDispatch;
  var windowDispatchOpt = Props.windowDispatch;
  var windowDispatch = windowDispatchOpt !== undefined ? windowDispatchOpt : (function (param) {
        
      });
  var type_ = player.type_;
  var rating = player.rating;
  var initialMatchCount = player.matchCount;
  var playerId = player.id;
  var form = useForm({
        firstName: player.firstName,
        lastName: player.lastName,
        rating: String(rating),
        matchCount: String(initialMatchCount)
      }, (function (param, cb) {
          Curry._1(playersDispatch, {
                TAG: /* Set */1,
                _0: playerId,
                _1: {
                  firstName: param.firstName,
                  id: playerId,
                  lastName: param.lastName,
                  matchCount: param.matchCount,
                  rating: param.rating,
                  type_: type_
                }
              });
          Curry._1(cb.notifyOnSuccess, undefined);
          return Curry._1(cb.reset, undefined);
        }));
  var playerName = form.input.firstName + " " + form.input.lastName;
  React.useEffect((function () {
          Curry._1(windowDispatch, {
                TAG: /* SetTitle */2,
                _0: "Profile for " + playerName
              });
          return (function (param) {
                    return Curry._1(windowDispatch, {
                                TAG: /* SetTitle */2,
                                _0: ""
                              });
                  });
        }), [
        windowDispatch,
        playerName
      ]);
  var avoidMap = Curry._1(Data_Id$Coronate.Pair.$$Set.toMap, config.avoidPairs);
  var singAvoidList = Belt_Map.getWithDefault(avoidMap, playerId, Belt_Set.make(Data_Id$Coronate.id));
  var unavoided = Belt_Array.keep(Belt_Map.keysToArray(players), (function (id) {
          if (Belt_Set.has(singAvoidList, id)) {
            return false;
          } else {
            return !Data_Id$Coronate.eq(id, playerId);
          }
        }));
  var match = React.useState(function () {
        return Belt_Array.get(unavoided, 0);
      });
  var setSelectedAvoider = match[1];
  var selectedAvoider = match[0];
  var avoidAdd = function ($$event) {
    $$event.preventDefault();
    if (selectedAvoider === undefined) {
      return ;
    }
    var selectedAvoider$1 = Caml_option.valFromOption(selectedAvoider);
    var pair = Data_Id$Coronate.Pair.make(playerId, selectedAvoider$1);
    if (pair === undefined) {
      return ;
    }
    Curry._1(configDispatch, {
          TAG: /* AddAvoidPair */0,
          _0: Caml_option.valFromOption(pair)
        });
    var id = Belt_Array.get(unavoided, 0);
    var newSelected;
    if (id !== undefined) {
      var id$1 = Caml_option.valFromOption(id);
      newSelected = Data_Id$Coronate.eq(id$1, selectedAvoider$1) ? Belt_Array.get(unavoided, 1) : Caml_option.some(id$1);
    } else {
      newSelected = Belt_Array.get(unavoided, 1);
    }
    return Curry._1(setSelectedAvoider, (function (param) {
                  return newSelected;
                }));
  };
  var handleAvoidChange = function ($$event) {
    var id = $$event.currentTarget.value;
    return Curry._1(setSelectedAvoider, (function (param) {
                  return id;
                }));
  };
  var handleAvoidBlur = function ($$event) {
    var id = $$event.currentTarget.value;
    return Curry._1(setSelectedAvoider, (function (param) {
                  return id;
                }));
  };
  var match$1 = form.matchCountResult;
  var tmp;
  tmp = match$1 !== undefined && match$1.TAG === /* Ok */0 ? Data_Ratings$Coronate.EloRank.getKFactor(match$1._0, rating) : Data_Ratings$Coronate.EloRank.getKFactor(initialMatchCount, rating);
  return React.createElement("div", {
              className: "content-area"
            }, React.createElement(Router$Coronate.Link.make, {
                  children: null,
                  to_: /* PlayerList */2,
                  onClick: (function ($$event) {
                      if (Curry._1(form.dirty, undefined) && !window.confirm("Discard changes?")) {
                        $$event.preventDefault();
                        return ;
                      }
                      
                    })
                }, React.createElement(ReactFeather.ChevronLeft, {}), " Back"), React.createElement("h2", undefined, "Profile for " + playerName), React.createElement("form", {
                  onSubmit: (function ($$event) {
                      $$event.preventDefault();
                      return Curry._1(form.submit, undefined);
                    })
                }, React.createElement("p", undefined, React.createElement("label", {
                          htmlFor: "firstName"
                        }, "First name"), React.createElement("input", {
                          name: "firstName",
                          type: "text",
                          value: form.input.firstName,
                          onBlur: (function (param) {
                              return Curry._1(form.blurFirstName, undefined);
                            }),
                          onChange: (function ($$event) {
                              return Curry._2(form.updateFirstName, (function (input, firstName) {
                                            return {
                                                    firstName: firstName,
                                                    lastName: input.lastName,
                                                    rating: input.rating,
                                                    matchCount: input.matchCount
                                                  };
                                          }), $$event.target.value);
                            })
                        })), errorNotification(form.firstNameResult), React.createElement("p", undefined, React.createElement("label", {
                          htmlFor: "lastName"
                        }, "Last name"), React.createElement("input", {
                          name: "lastName",
                          type: "text",
                          value: form.input.lastName,
                          onBlur: (function (param) {
                              return Curry._1(form.blurLastName, undefined);
                            }),
                          onChange: (function ($$event) {
                              return Curry._2(form.updateLastName, (function (input, lastName) {
                                            return {
                                                    firstName: input.firstName,
                                                    lastName: lastName,
                                                    rating: input.rating,
                                                    matchCount: input.matchCount
                                                  };
                                          }), $$event.target.value);
                            })
                        })), errorNotification(form.lastNameResult), React.createElement("p", undefined, React.createElement("label", {
                          htmlFor: "matchCount"
                        }, "Matches played"), React.createElement("input", {
                          name: "matchCount",
                          type: "number",
                          value: form.input.matchCount,
                          onBlur: (function (param) {
                              return Curry._1(form.blurMatchCount, undefined);
                            }),
                          onChange: (function ($$event) {
                              return Curry._2(form.updateMatchCount, (function (input, matchCount) {
                                            return {
                                                    firstName: input.firstName,
                                                    lastName: input.lastName,
                                                    rating: input.rating,
                                                    matchCount: matchCount
                                                  };
                                          }), $$event.target.value);
                            })
                        })), errorNotification(form.matchCountResult), React.createElement("p", undefined, React.createElement("label", {
                          htmlFor: "rating"
                        }, "Rating"), React.createElement("input", {
                          name: "rating",
                          type: "number",
                          value: form.input.rating,
                          onBlur: (function (param) {
                              return Curry._1(form.blurRating, undefined);
                            }),
                          onChange: (function ($$event) {
                              return Curry._2(form.updateRating, (function (input, rating) {
                                            return {
                                                    firstName: input.firstName,
                                                    lastName: input.lastName,
                                                    rating: rating,
                                                    matchCount: input.matchCount
                                                  };
                                          }), $$event.target.value);
                            })
                        })), errorNotification(form.ratingResult), React.createElement("p", undefined, React.createElement("button", {
                          disabled: form.submitting || !Curry._1(form.valid, undefined)
                        }, Curry._1(form.dirty, undefined) ? "Save" : "Saved"))), React.createElement("h3", undefined, "Players to avoid"), Belt_Set.isEmpty(singAvoidList) ? React.createElement("p", undefined, "None") : React.createElement("ul", undefined, Belt_Array.map(Belt_Set.toArray(singAvoidList), (function (pId) {
                          var fullName = Data_Player$Coronate.fullName(Data_Player$Coronate.getMaybe(players, pId));
                          return React.createElement("li", {
                                      key: Data_Id$Coronate.toString(pId)
                                    }, fullName, React.createElement("button", {
                                          "aria-label": "Remove " + fullName + " from avoid list.",
                                          className: "danger button-ghost",
                                          title: "Remove " + fullName + " from avoid list.",
                                          onClick: (function (param) {
                                              var pair = Data_Id$Coronate.Pair.make(playerId, pId);
                                              if (pair !== undefined) {
                                                return Curry._1(configDispatch, {
                                                            TAG: /* DelAvoidPair */1,
                                                            _0: Caml_option.valFromOption(pair)
                                                          });
                                              }
                                              
                                            })
                                        }, React.createElement(ReactFeather.Trash2, {})));
                        }))), React.createElement("form", {
                  onSubmit: avoidAdd
                }, React.createElement("label", {
                      htmlFor: "avoid-select"
                    }, "Select a new player to avoid."), selectedAvoider !== undefined ? React.createElement(React.Fragment, undefined, React.createElement("select", {
                            id: "avoid-select",
                            value: Data_Id$Coronate.toString(Caml_option.valFromOption(selectedAvoider)),
                            onBlur: handleAvoidBlur,
                            onChange: handleAvoidChange
                          }, Belt_Array.map(unavoided, (function (pId) {
                                  return React.createElement("option", {
                                              key: Data_Id$Coronate.toString(pId),
                                              value: Data_Id$Coronate.toString(pId)
                                            }, Data_Player$Coronate.fullName(Data_Player$Coronate.getMaybe(players, pId)));
                                }))), " ", React.createElement("input", {
                            className: "button-micro",
                            type: "submit",
                            value: "Add"
                          })) : "No players are available to avoid."), React.createElement("hr", undefined), React.createElement("details", undefined, React.createElement("summary", undefined, "Additional information"), React.createElement("dl", undefined, React.createElement("dt", undefined, "K-factor"), React.createElement("dd", {
                          className: "monospace"
                        }, tmp)), React.createElement("p", {
                      className: "caption-20"
                    }, "K-factor is 40 for players who have played fewer than 30 matches, 20 for players with\n            a rating below 2100, and 10 for players with a rating above 2100.")));
}

var Profile = {
  make: PagePlayers$Profile
};

function PagePlayers(Props) {
  var id = Props.id;
  var windowDispatch = Props.windowDispatch;
  var match = Db$Coronate.useAllPlayers(undefined);
  var playersDispatch = match.dispatch;
  var players = match.items;
  var match$1 = Hooks$Coronate.useSortedTable(Belt_Map.valuesToArray(players), sortName, false);
  var sortDispatch = match$1[1];
  React.useEffect((function () {
          Curry._1(sortDispatch, {
                TAG: /* SetTable */2,
                _0: Belt_Map.valuesToArray(players)
              });
          
        }), [
        players,
        sortDispatch
      ]);
  var match$2 = Db$Coronate.useConfig(undefined);
  var configDispatch = match$2[1];
  var tmp;
  if (id !== undefined) {
    var player = Belt_Map.get(players, Caml_option.valFromOption(id));
    tmp = player !== undefined ? React.createElement(PagePlayers$Profile, {
            player: player,
            players: players,
            playersDispatch: playersDispatch,
            config: match$2[0],
            configDispatch: configDispatch,
            windowDispatch: windowDispatch
          }) : React.createElement("div", undefined, "Loading...");
  } else {
    tmp = React.createElement(PagePlayers$PlayerList, {
          sorted: match$1[0],
          sortDispatch: sortDispatch,
          players: players,
          playersDispatch: playersDispatch,
          configDispatch: configDispatch,
          windowDispatch: windowDispatch
        });
  }
  return React.createElement(Window$Coronate.Body.make, {
              children: tmp,
              windowDispatch: windowDispatch
            });
}

var make = PagePlayers;

export {
  NewPlayerForm ,
  Profile ,
  make ,
  
}
/* react Not a pure module */
