// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Localforage from "localforage";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";

function make(config, data) {
  return {
          store: Localforage.createInstance(config),
          encode: data.encode,
          decode: data.decode
        };
}

function get(param) {
  var decode = param.decode;
  return param.store.getItems().then(function (items) {
              var items$1;
              try {
                items$1 = decode(items);
              }
              catch (raw_error){
                return Promise.reject(Caml_js_exceptions.internalToOCamlException(raw_error));
              }
              return Promise.resolve(items$1);
            });
}

function set(param, items) {
  return param.store.setItems(param.encode(items));
}

export {
  make ,
  get ,
  set ,
  
}
/* localforage Not a pure module */
