// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Dialog from "@reach/dialog";

var $$FileReader = {};

var VisuallyHidden = {};

function Externals$Dialog(Props) {
  var isOpen = Props.isOpen;
  var onDismiss = Props.onDismiss;
  var ariaLabel = Props.ariaLabel;
  var children = Props.children;
  var styleOpt = Props.style;
  var className = Props.className;
  var style = styleOpt !== undefined ? Caml_option.valFromOption(styleOpt) : ({});
  return React.createElement(Dialog.Dialog, {
              isOpen: isOpen,
              onDismiss: onDismiss,
              style: style,
              "aria-label": ariaLabel,
              children: children,
              className: className
            });
}

var Dialog$1 = {
  make: Externals$Dialog
};

var Tabs = {};

var TabList = {};

var Tab = {};

var TabPanels = {};

var TabPanel = {};

var ReachTabs = {
  Tabs: Tabs,
  TabList: TabList,
  Tab: Tab,
  TabPanels: TabPanels,
  TabPanel: TabPanel
};

export {
  $$FileReader ,
  VisuallyHidden ,
  Dialog$1 as Dialog,
  ReachTabs ,
  
}
/* react Not a pure module */
