// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import Numeral from "numeral";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as Data_Id$Coronate from "./Data_Id.bs.js";
import * as Belt_MutableQueue from "rescript/lib/es6/belt_MutableQueue.js";
import * as Data_Rounds$Coronate from "./Data_Rounds.bs.js";

function add(a, b) {
  return a + b;
}

var compare = Caml.caml_float_compare;

function toFloat(x) {
  return x;
}

function toNumeral(prim) {
  return Numeral(prim);
}

function toFloat$1(x) {
  switch (x) {
    case /* Zero */0 :
        return 0.0;
    case /* One */1 :
        return 1.0;
    case /* NegOne */2 :
        return -1.0;
    case /* Half */3 :
        return 0.5;
    
  }
}

function add$1(a, b) {
  return a + toFloat$1(b);
}

function sum(list) {
  return Belt_List.reduce(list, 0.0, add$1);
}

function calcScore(results, adjustment) {
  return Belt_List.reduce(results, 0.0, add$1) + adjustment;
}

function fromResultWhite(x) {
  if (x >= 3) {
    return /* Zero */0;
  }
  switch (x) {
    case /* WhiteWon */0 :
        return /* One */1;
    case /* BlackWon */1 :
        return /* Zero */0;
    case /* Draw */2 :
        return /* Half */3;
    
  }
}

function fromResultBlack(x) {
  if (x === 0) {
    return /* Zero */0;
  }
  switch (x) {
    case /* BlackWon */1 :
        return /* One */1;
    case /* Draw */2 :
        return /* Half */3;
    case /* NotSet */3 :
        return /* Zero */0;
    
  }
}

function opposite(x) {
  if (x) {
    return /* White */0;
  } else {
    return /* Black */1;
  }
}

function toScore(x) {
  if (x) {
    return /* One */1;
  } else {
    return /* NegOne */2;
  }
}

var Color = {
  opposite: opposite,
  toScore: toScore
};

function oppResultsToSumById(param, id) {
  return Belt_List.reduce(param.opponentResults, undefined, (function (acc, param) {
                var result = param[1];
                if (Data_Id$Coronate.eq(id, param[0])) {
                  if (acc !== undefined) {
                    return Caml_option.some(Caml_option.valFromOption(acc) + toFloat$1(result));
                  } else {
                    return Caml_option.some(0.0 + toFloat$1(result));
                  }
                } else {
                  return acc;
                }
              }));
}

function toString(data) {
  switch (data) {
    case /* Median */0 :
        return "median";
    case /* Solkoff */1 :
        return "solkoff";
    case /* Cumulative */2 :
        return "cumulative";
    case /* CumulativeOfOpposition */3 :
        return "cumulativeOfOpposition";
    case /* MostBlack */4 :
        return "mostBlack";
    
  }
}

function toPrettyString(tieBreak) {
  switch (tieBreak) {
    case /* Median */0 :
        return "Modified median";
    case /* Solkoff */1 :
        return "Solkoff";
    case /* Cumulative */2 :
        return "Cumulative";
    case /* CumulativeOfOpposition */3 :
        return "Cumulative of opposition";
    case /* MostBlack */4 :
        return "Most black";
    
  }
}

var encode = toString;

function decode(json) {
  var json$1 = Belt_Option.getExn(Js_json.decodeString(json));
  switch (json$1) {
    case "cumulative" :
        return /* Cumulative */2;
    case "cumulativeOfOpposition" :
        return /* CumulativeOfOpposition */3;
    case "median" :
        return /* Median */0;
    case "mostBlack" :
        return /* MostBlack */4;
    case "solkoff" :
        return /* Solkoff */1;
    default:
      return /* Median */0;
  }
}

function make(id) {
  return {
          colorScores: /* [] */0,
          lastColor: undefined,
          id: id,
          isDummy: false,
          opponentResults: /* [] */0,
          ratings: /* [] */0,
          firstRating: 0,
          results: /* [] */0,
          resultsNoByes: /* [] */0,
          adjustment: 0.0
        };
}

function isNotDummy(scores, oppId) {
  var opponent = Belt_Map.get(scores, oppId);
  if (opponent !== undefined) {
    return !opponent.isDummy;
  } else {
    return true;
  }
}

function getPlayerScore(scores, id) {
  var match = Belt_Map.get(scores, id);
  if (match !== undefined) {
    return calcScore(match.results, match.adjustment);
  } else {
    return 0.0;
  }
}

function getOpponentScores(scores, id) {
  var match = Belt_Map.get(scores, id);
  if (match !== undefined) {
    return Belt_List.keepMap(match.opponentResults, (function (param) {
                  var oppId = param[0];
                  if (isNotDummy(scores, oppId)) {
                    return Caml_option.some(getPlayerScore(scores, oppId));
                  }
                  
                }));
  } else {
    return /* [] */0;
  }
}

function getMedianScore(scores, id) {
  var oppScores = getOpponentScores(scores, id);
  var size = Belt_List.size(oppScores);
  return Belt_List.reduce(Belt_List.keepWithIndex(Belt_List.sort(oppScores, compare), (function (param, i) {
                    return !(i === 0 || i === (size - 1 | 0));
                  })), 0.0, add);
}

function runningReducer(acc, score) {
  if (!acc) {
    return {
            hd: toFloat$1(score),
            tl: /* [] */0
          };
  }
  var last = acc.hd;
  return {
          hd: last + toFloat$1(score),
          tl: {
            hd: last,
            tl: acc.tl
          }
        };
}

function getCumulativeScore(scores, id) {
  var match = Belt_Map.get(scores, id);
  if (match !== undefined) {
    var results = Belt_List.reduce(match.resultsNoByes, /* [] */0, runningReducer);
    var adjustment = match.adjustment;
    return Belt_List.reduce(results, 0.0, add) + adjustment;
  } else {
    return 0.0;
  }
}

function getCumulativeOfOpponentScore(scores, id) {
  var match = Belt_Map.get(scores, id);
  if (match !== undefined) {
    return Belt_List.reduce(Belt_List.keepMap(match.opponentResults, (function (param) {
                      var id = param[0];
                      if (isNotDummy(scores, id)) {
                        return Caml_option.some(getCumulativeScore(scores, id));
                      }
                      
                    })), 0.0, add);
  } else {
    return 0.0;
  }
}

function getColorBalanceScore(scores, id) {
  var match = Belt_Map.get(scores, id);
  if (match !== undefined) {
    return Belt_List.reduce(match.colorScores, 0.0, add$1);
  } else {
    return 0.0;
  }
}

function getTieBreak(scores, x) {
  switch (x) {
    case /* Median */0 :
        return scores.median;
    case /* Solkoff */1 :
        return scores.solkoff;
    case /* Cumulative */2 :
        return scores.cumulative;
    case /* CumulativeOfOpposition */3 :
        return scores.cumulativeOfOpposition;
    case /* MostBlack */4 :
        return scores.mostBlack;
    
  }
}

function createStandingArray(t, orderedMethods) {
  return Belt_SortArray.stableSortBy(Belt_Map.valuesToArray(Belt_Map.map(t, (function (param) {
                        var id = param.id;
                        return {
                                id: id,
                                score: calcScore(param.results, param.adjustment),
                                median: getMedianScore(t, id),
                                solkoff: Belt_List.reduce(getOpponentScores(t, id), 0.0, add),
                                cumulative: getCumulativeScore(t, id),
                                cumulativeOfOpposition: getCumulativeOfOpponentScore(t, id),
                                mostBlack: getColorBalanceScore(t, id)
                              };
                      }))), (function (param, param$1) {
                var x = Caml.caml_float_compare(param$1.score, param.score);
                if (x !== 0) {
                  return x;
                } else {
                  var _i = 0;
                  while(true) {
                    var i = _i;
                    var tieBreak = Belt_Array.get(orderedMethods, i);
                    if (tieBreak === undefined) {
                      return 0;
                    }
                    var match = getTieBreak(param, tieBreak);
                    var match$1 = getTieBreak(param$1, tieBreak);
                    var x$1 = Caml.caml_float_compare(match$1, match);
                    if (x$1 !== 0) {
                      return x$1;
                    }
                    _i = i + 1 | 0;
                    continue ;
                  };
                }
              }));
}

function eq(a, b, tieBreaks) {
  if (a.score === b.score) {
    return Belt_Array.every(tieBreaks, (function (tb) {
                  return getTieBreak(a, tb) === getTieBreak(b, tb);
                }));
  } else {
    return false;
  }
}

function createStandingTree(standingArray, tieBreaks) {
  return Belt_Array.reduce(standingArray, /* [] */0, (function (tree, standing) {
                if (!tree) {
                  return {
                          hd: {
                            hd: standing,
                            tl: /* [] */0
                          },
                          tl: /* [] */0
                        };
                }
                var treeTail = tree.tl;
                var treeHead = tree.hd;
                if (treeHead) {
                  if (eq(treeHead.hd, standing, tieBreaks)) {
                    return {
                            hd: {
                              hd: standing,
                              tl: treeHead
                            },
                            tl: treeTail
                          };
                  } else {
                    return {
                            hd: {
                              hd: standing,
                              tl: /* [] */0
                            },
                            tl: {
                              hd: treeHead,
                              tl: treeTail
                            }
                          };
                  }
                } else {
                  return {
                          hd: {
                            hd: standing,
                            tl: /* [] */0
                          },
                          tl: tree
                        };
                }
              }));
}

function update(data, playerId, origRating, newRating, result, oppId, color, scoreAdjustments) {
  if (data !== undefined) {
    return {
            colorScores: {
              hd: color ? /* One */1 : /* NegOne */2,
              tl: data.colorScores
            },
            lastColor: color,
            id: data.id,
            isDummy: data.isDummy,
            opponentResults: {
              hd: [
                oppId,
                result
              ],
              tl: data.opponentResults
            },
            ratings: {
              hd: newRating,
              tl: data.ratings
            },
            firstRating: data.firstRating,
            results: {
              hd: result,
              tl: data.results
            },
            resultsNoByes: Data_Id$Coronate.isDummy(oppId) ? data.resultsNoByes : ({
                  hd: result,
                  tl: data.resultsNoByes
                }),
            adjustment: data.adjustment
          };
  } else {
    return {
            colorScores: {
              hd: color ? /* One */1 : /* NegOne */2,
              tl: /* [] */0
            },
            lastColor: color,
            id: playerId,
            isDummy: Data_Id$Coronate.isDummy(playerId),
            opponentResults: {
              hd: [
                oppId,
                result
              ],
              tl: /* [] */0
            },
            ratings: {
              hd: newRating,
              tl: /* [] */0
            },
            firstRating: origRating,
            results: {
              hd: result,
              tl: /* [] */0
            },
            resultsNoByes: Data_Id$Coronate.isDummy(oppId) ? /* [] */0 : ({
                  hd: result,
                  tl: /* [] */0
                }),
            adjustment: Belt_Map.getWithDefault(scoreAdjustments, playerId, 0.0)
          };
  }
}

function fromTournament(roundList, scoreAdjustments) {
  return Belt_MutableQueue.reduce(Data_Rounds$Coronate.rounds2Matches(roundList), Belt_Map.make(Data_Id$Coronate.id), (function (acc, match) {
                var match$1 = match.result;
                if (match$1 >= 3) {
                  return acc;
                }
                var arg = match.whiteId;
                var arg$1 = match.whiteOrigRating;
                var arg$2 = match.whiteNewRating;
                var arg$3 = fromResultWhite(match.result);
                var arg$4 = match.blackId;
                var whiteUpdate = function (param) {
                  return update(param, arg, arg$1, arg$2, arg$3, arg$4, /* White */0, scoreAdjustments);
                };
                var arg$5 = match.blackId;
                var arg$6 = match.blackOrigRating;
                var arg$7 = match.blackNewRating;
                var arg$8 = fromResultBlack(match.result);
                var arg$9 = match.whiteId;
                var blackUpdate = function (param) {
                  return update(param, arg$5, arg$6, arg$7, arg$8, arg$9, /* Black */1, scoreAdjustments);
                };
                return Belt_Map.update(Belt_Map.update(acc, match.whiteId, whiteUpdate), match.blackId, blackUpdate);
              }));
}

var Score_Sum = {
  toFloat: toFloat,
  toNumeral: toNumeral
};

var Score = {
  Sum: Score_Sum,
  fromResultWhite: fromResultWhite,
  fromResultBlack: fromResultBlack,
  sum: sum,
  calcScore: calcScore,
  toFloat: toFloat$1
};

var TieBreak = {
  encode: encode,
  decode: decode,
  toString: toString,
  toPrettyString: toPrettyString
};

export {
  Score ,
  Color ,
  oppResultsToSumById ,
  TieBreak ,
  make ,
  getTieBreak ,
  createStandingArray ,
  createStandingTree ,
  fromTournament ,
  
}
/* numeral Not a pure module */
